import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';
import { withRouter } from 'next/router';

import { notify } from 'Components/common/notify';

import useCaptureClickOutside from 'Hooks/useCaptureClickOutside';
import { trackEvent } from 'Utils/analytics';
import { arrayMedian } from 'Utils/arrayUtils';
import {
	getAnalyticsFlowType,
	isExternalSeatmapProduct,
	routeToBookingFlow,
} from 'Utils/bookingFlowUtils';
import { getPriceTagFromCalendarInv } from 'Utils/calendarUtils';
import {
	getBooking,
	setBookingDate,
	setBookingTourId,
	setBookingVariantId,
} from 'Utils/crossSell/stateSelectors';
import {
	addDay,
	format,
	formatInMonthTitleFormat,
	formatToDay,
	getLastAndFirstDayOfMonthAndYear,
	localDateToJsDate,
} from 'Utils/dateUtils';
import dayjs from 'Utils/dayjsUtil';
import PlatformUtils from 'Utils/platformUtils';
import {
	hasComboVariants,
	hasMultipleAvailableVariants,
} from 'Utils/productUtils';
/* Utils, Actions, Hooks */
import {
	getCalendarInventories,
	getCurrentPage,
	getDomainConfig,
	getPricing,
	getProduct,
	getSelectedDate,
} from 'Utils/stateUtils';

import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from 'Constants/analytics';
import { CALENDAR_CONSTS } from 'Constants/constants';
import { strings } from 'Constants/strings';

const RegularCalendar = dynamic(
	() => import('Containers/desktop/regularCalendar'),
);
const SevenDaysCalendar = dynamic(
	() => import('Containers/desktop/sevenDaysCalendar'),
);
const DateListCalendar = dynamic(
	() => import('Containers/desktop/dateListCalendar'),
);
const MobileCalendar = dynamic(() => import('Containers/mobile/calendar'));
const CalendarLoader = dynamic(
	() => import('Components/common/calendarLoader'),
);
const RegularCalendarForBroadway = dynamic(
	() =>
		import(
			/* webpackChunkName: "RegularCalendarForBroadway" */
			'Containers/common/regularCalendarForBroadway'
		),
);

const { CAL_TYPES, MAX_INVS_IN_DATE_LIST_CAL, TOTAL_WEEK_DAYS } =
	CALENDAR_CONSTS;

const Calendar = ({
	id,
	pricing,
	onClose,
	excludedClassesForClosingCalendar,
	selectedDate,
	changeBookingDate,
	onTourChange,
	onVariantChange,
	booking,
	router,
	useGetTicketsClicked,
	isGetTicketsClicked,
	shouldScrollIntoView,
	isComparisonTable,
	hidePrice,
	isSeatmap,
	isSvg,
	isAltFlow,
	changeTourDate,
	tourId,
	selectedTourDate,
	inventoryData,
	trackDateSelected,
	trackCalendarDateSelected = true,
	domainConfig: { name },
	product,
	isStaticPositioned,
	allowPageScroll = true,
	onDateChange,
	afterDateChange,
	calendarTypeOverride = null,
	isLtt,
	showTimeSlots,
	onFetchPricing,
	afterDelayOnOverlayClick,
	onOverlayClick,
	animateFromZero,
	showOverlay = true,
	animateOpening = true,
	animateClosing = true,
	onTempDateChange,
	closeOnOverlayClicked = true,
	routeToBookingFlow: routeToBookingFlowOnTimeSlotClicked = true,
	highlightSelectedDate = true,
	comparisonTableProductInfo,
	isSingleCalendar,
	closeDateChangedBanner,
	shouldNotFireCalendarOpenEvents = false,
	changeQueryOnSelection,
	isCrossSell = false,
	showLoader = true,
	isSvgCalendar = false,
}: any) => {
	const isDesktop = PlatformUtils.isDesktop();
	const OVERLAY_DISABLED_TIME = isDesktop ? 400 : 600;
	const CALENDAR_CLOSE_TIME = isDesktop ? 150 : 250;
	let calendarType: any;
	const [node, setNode] = useState(null);
	const [minInventoryMap, setMinInventoryMap] = useState(null);
	const [isCalendarClosing, setCalendarClosing] = useState(false);
	const [isCalendarOpening, setCalendarOpening] = useState(true);
	const hasCustomDateChange = !!onDateChange;
	const closeCalendar = ({ isDelayed = true, isOverlayClicked = false }) => {
		if (
			!hasCustomDateChange &&
			!showTimeSlots &&
			!shouldNotFireCalendarOpenEvents
		)
			trackEvent({
				eventName: 'Calendar Closed',
				'Tour Group ID': Number(id),
				'Tour ID': tourId ? tourId : null,
				'Calendar Type': calendarType,
			});
		if (isDelayed && onClose) {
			setCalendarClosing(true);
			setTimeout(() => {
				onClose({ isOverlayClicked });
				setCalendarClosing(false);
			}, CALENDAR_CLOSE_TIME);
		} else if (onClose) onClose({ isOverlayClicked });
	};

	const handleClose = (e: any) => {
		const { target } = e;
		const targetClassList = [...target.classList];

		if (!closeOnOverlayClicked) {
			notify.show(strings.PLEASE_SELECT_DATE, 'error', 4000);
			return;
		}

		if (isCalendarOpening && animateOpening) return;

		// don't close if target has excluded class
		if (
			!excludedClassesForClosingCalendar?.some((el: any) =>
				targetClassList.includes(el),
			)
		) {
			if (onOverlayClick) onOverlayClick();
			setCalendarClosing(true);
			setTimeout(() => {
				if (afterDelayOnOverlayClick) afterDelayOnOverlayClick();
				closeCalendar({ isDelayed: false, isOverlayClicked: true });
			}, CALENDAR_CLOSE_TIME);
		}
	};

	const getNode = useCallback((node: any) => {
		if (node !== null) setNode(node);
	}, []);

	const sendCalendarOpenedEvent = useCallback(
		(id: string, type: string) => {
			if (shouldNotFireCalendarOpenEvents) return;
			trackEvent({
				eventName: 'Calendar Opened',
				'Tour Group ID': Number(id),
				'Tour ID': tourId ? tourId : null,
				'Calendar Type': type,
			});
		},
		[tourId],
	);

	// @ts-expect-error TS(7006): Parameter 'date' implicitly has an 'any' type.
	const sendDateSelectedEvent = (date, type, isMinPrice) => {
		if (trackDateSelected) {
			trackDateSelected({ date, tourId, id });
		}
		if (trackCalendarDateSelected)
			trackEvent({
				eventName: 'Calendar Date Selected',
				'Tour Group ID': Number(id),
				'Tour ID': tourId ? tourId : null,
				'Selected Date': date,
				'Table Type': isComparisonTable ? 'Comparison Table' : 'Normal',
				'Calendar Type': type,
				'Is Minimum Price': isMinPrice,
			});
	};

	const sendNextClickedEvent = (type: any, direction: any) => {
		trackEvent({
			eventName: 'Calendar Next Button Clicked',
			'Tour Group ID': Number(id),
			'Tour ID': tourId ? tourId : null,
			'Calendar Type': type,
			Direction: direction === 1 ? 'Next' : 'Previous',
		});
	};

	// @ts-expect-error TS(7006): Parameter 'date' implicitly has an 'any' type.
	const onDateSelected = (date, priceTag, type, isMinPrice) => {
		const isCombo = hasComboVariants(product);
		const selectedDateAsString = String(selectedDate);
		const fullDateAsString = format(date, 'yyyy-mm-dd', true);
		if (selectedDateAsString === fullDateAsString && !showTimeSlots) {
			closeCalendar({ isDelayed: !isSeatmap });
		} else if (priceTag) {
			sendDateSelectedEvent(date, type, isMinPrice);

			if (isCombo) {
				changeTourDate({ id, date, tourId }, true);
			} else {
				if (onTempDateChange) {
					onTempDateChange(date);
				} else {
					if (!isCrossSell) {
						changeBookingDate({ id, date }, changeQueryOnSelection);
					}
				}

				// clears the tour selection on date change
				if (
					hasMultipleAvailableVariants(product) &&
					!isSeatmap &&
					!isSvg &&
					!isAltFlow &&
					!booking?.isExperimentalFlow &&
					!isCrossSell
				) {
					onTourChange(
						{ id, tourId: null, defaultSelection: true },
						true,
					);
					onVariantChange(
						{
							id,
							variantId: null,
							defaultSelection: true,
						},
						true,
					);
				}
			}
			if (!showTimeSlots) closeCalendar({ isDelayed: !isSeatmap });
		}

		// move to next page
		if (useGetTicketsClicked && isGetTicketsClicked) {
			// NOTE: setting selectedDate explicitly since booking object doesn't update via mapStateToProps
			const {
				query: { lang },
			} = router;
			routeToBookingFlow(
				id,
				{
					...booking,
					selectedDate: fullDateAsString,
				},
				{
					lang,
					date,
				},
				false,
				isComparisonTable ? comparisonTableProductInfo : product,
			);
		}

		if (closeDateChangedBanner) closeDateChangedBanner();

		if (hasCustomDateChange) {
			onDateChange();
			trackEvent({
				eventName: 'Calendar Closed',
				'Tour Group ID': Number(id),
				'Tour ID': tourId ? tourId : null,
				'Calendar Type': calendarType,
			});
		}
	};

	const priceOf = (inventory: any) => {
		if (!inventory) return Number.MAX_SAFE_INTEGER;
		return inventory?.listingPrice;
	};

	const computeMinInventoryMap = useCallback(() => {
		let { inventoryMap } = inventoryData;
		let minInventoryMap = new Map(Object.entries(inventoryMap));
		// @ts-expect-error TS(2345): Argument of type 'Map<string, unknown>' is not ass... Remove this comment to see the full error message
		setMinInventoryMap(minInventoryMap);
	}, [inventoryData]);

	const getMedianPrice = () => {
		const priceList = new Set();
		// @ts-expect-error TS(2531): Object is possibly 'null'.
		minInventoryMap.forEach(inv => {
			const price = priceOf(inv);
			priceList.add(price);
		});
		// @ts-expect-error TS(2802): Type 'Set<unknown>' can only be iterated through w... Remove this comment to see the full error message
		let median = arrayMedian([...priceList]);

		// Don't show the only price as discounted
		if (priceList.size === 1) median -= 1;
		return median;
	};

	const getInventoryStats = () => {
		const monthWiseCount = {};
		let maxInvInAMonth = 0,
			totalInvs = 0;

		// @ts-expect-error TS(2531): Object is possibly 'null'.
		minInventoryMap.forEach((inv, date) => {
			const month = formatInMonthTitleFormat(date);
			// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
			let count = monthWiseCount[month];
			totalInvs++;
			if (count) {
				// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
				monthWiseCount[month] = ++count;
			} else {
				// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
				monthWiseCount[month] = 1;
			}
		});

		Object.values(monthWiseCount).forEach(invsCount => {
			if ((invsCount as any) > maxInvInAMonth)
				// @ts-expect-error TS(2322): Type 'unknown' is not assignable to type 'number'.
				maxInvInAMonth = invsCount;
		});

		return {
			totalInvs,
			maxInvInAMonth,
		};
	};

	const getCalendarType = (totalInvs: any, maxInvInAMonth: any) => {
		if (!isSeatmap && calendarTypeOverride) return calendarTypeOverride;
		if (
			!isSeatmap &&
			!isExternalSeatmapProduct(product) &&
			totalInvs <= MAX_INVS_IN_DATE_LIST_CAL &&
			isDesktop &&
			!isSingleCalendar
		)
			return CAL_TYPES.DATE_LIST_CALENDAR;
		if (
			!isSeatmap &&
			maxInvInAMonth <= TOTAL_WEEK_DAYS &&
			!isExternalSeatmapProduct(product)
		)
			return CAL_TYPES.SEVEN_DAY_CALENDAR;
		return CAL_TYPES.REGULAR_CALENDAR;
	};

	const getDesiredFullMonthDate = (monthDate: any, isLastDayDate: any) => {
		const dateAsArray = monthDate.split('-'),
			month = dateAsArray[1],
			year = dateAsArray[0];
		return getLastAndFirstDayOfMonthAndYear(year, month, isLastDayDate);
	};

	const getAllDates = () => {
		let allDates = [];
		const { fromDate: fromDateAsString, toDate: toDateAsString } =
			inventoryData;
		const firstDayOfMonthInventory = getDesiredFullMonthDate(
			fromDateAsString,
			false,
		);
		const lastDayOfMonthInventory = getDesiredFullMonthDate(
			toDateAsString,
			true,
		);
		const fromDate = localDateToJsDate(firstDayOfMonthInventory);
		const toDate = localDateToJsDate(lastDayOfMonthInventory);
		let curDate = addDay(fromDate, 0);

		while (curDate.getTime() <= (toDate as any).getTime()) {
			allDates.push(curDate);
			curDate = addDay(curDate, 1);
		}

		return allDates;
	};

	const getDistinctMonths = (dates: any) => {
		dates = dates.map((x: any) => formatInMonthTitleFormat(x));
		dates = new Set(dates);
		return [...dates];
	};

	const getEmptyDates = (firstDate: any) =>
		Array.from(new Array(firstDate.getDay()), (_, index) => ({
			isDummy: false,
			isEmpty: true,
			isAvailable: false,
			date: null,
			day: null,
			priceTag: null,
			key: index,
		}));

	const getDummyDates = (dates: any, offset: any) =>
		dates.map((date: any, index: any) => ({
			isDummy: true,
			isEmpty: false,
			isAvailable: false,
			day: null,
			date: format(date, 'yyyy-mm-dd'), // not passing third arguement as date here is an object
			priceTag: null,
			key: index + offset,
		}));

	const getMonthDateList = (month: any, allDates: any) => {
		const { currency } = inventoryData;
		const monthDates = allDates.filter(
			(x: any) => formatInMonthTitleFormat(x) === month,
		);
		const dummyEmptyObjects = getEmptyDates(monthDates[0]);
		const dummyEmptyListsLength = dummyEmptyObjects.length;
		// if inventorymap isn't available just return dummy dates
		if (!minInventoryMap) {
			return dummyEmptyObjects.concat(
				[],
				getDummyDates(monthDates, dummyEmptyListsLength),
			);
		}
		// slice the complete date list if the month has no inventory available
		let sliceCount = 0;
		monthDates.forEach((date: any) => {
			const dateString = format(date, 'yyyy-mm-dd'); // not passing third arguement here as date is an object
			const minInventory = (minInventoryMap as any).get(dateString);
			if (!minInventory) sliceCount++;
		});
		const monthDatesToShow =
			sliceCount === monthDates.length
				? monthDates.slice(sliceCount)
				: monthDates;
		const emptyObjects = monthDatesToShow[0]
			? getEmptyDates(monthDatesToShow[0])
			: [];
		// @ts-expect-error TS(2769): No overload matches this call.
		const dateObjects = [].concat(emptyObjects);
		const datePriceList = [...dateObjects];

		monthDatesToShow.forEach((date: any, index: any) => {
			const dateString = format(date, 'yyyy-mm-dd'); // not passing third arguement here as date is an object
			const minInventory = (minInventoryMap as any).get(dateString);
			const priceTag = getPriceTagFromCalendarInv(minInventory, currency);
			const key = index + emptyObjects.length;
			const day = formatToDay(dayjs(date));
			// @ts-expect-error
			datePriceList.push({
				isDummy: false,
				isEmpty: false,
				date: dateString,
				day,
				isAvailable: priceTag ? true : false,
				priceTag,
				key,
			});
		});

		return monthDatesToShow.length === 0 ? new Map() : datePriceList;
	};

	const getDateLists = () => {
		const allDates = getAllDates();
		const months = getDistinctMonths(allDates);
		const monthsMap = new Map();

		months.map(month => {
			const datesList = getMonthDateList(month, allDates);
			if ((datesList as any).length > 0) monthsMap.set(month, datesList);
		});
		return monthsMap;
	};

	const getMonthWiseInventoryLists = () => {
		const inventoryListsMap = new Map();
		const { currency } = inventoryData;

		// @ts-expect-error TS(2531): Object is possibly 'null'.
		minInventoryMap.forEach((inv, date) => {
			const priceTag = getPriceTagFromCalendarInv(inv, currency);
			const month = formatInMonthTitleFormat(date);
			let datesList = inventoryListsMap.get(month);

			if (!datesList) {
				datesList = [];
			}
			const key = date;
			const day = formatToDay(dayjs(date));

			datesList.push({
				date,
				day,
				isEmpty: false,
				isDummy: false,
				isAvailable: priceTag ? true : false,
				priceTag,
				key,
			});
			inventoryListsMap.set(month, datesList);
		});

		return inventoryListsMap;
	};

	const getMonthInvLists = (calendarType: any) => {
		if (
			(calendarType === CAL_TYPES.SEVEN_DAY_CALENDAR ||
				calendarType === CAL_TYPES.DATE_LIST_CALENDAR) &&
			!isSingleCalendar
		)
			return getMonthWiseInventoryLists();
		return getDateLists();
	};

	const getDesktopComponent = (type: any, propsObj: any) => {
		switch (type) {
			case CAL_TYPES.DATE_LIST_CALENDAR:
				return <DateListCalendar {...propsObj} />;
			case CAL_TYPES.SEVEN_DAY_CALENDAR:
				return <SevenDaysCalendar {...propsObj} />;
			default:
				return <RegularCalendar {...propsObj} />;
		}
	};

	useCaptureClickOutside({ current: node }, handleClose);

	useEffect(() => {
		if (isDesktop || isCrossSell) return;
		trackEvent({
			eventName: 'Select Page Drawer Open',
			drawerType: 'Calendar',
		});
	}, []);

	useEffect(() => {
		const handleKeyPress = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				setCalendarClosing(true);
				setTimeout(() => {
					if (afterDelayOnOverlayClick) afterDelayOnOverlayClick();
					closeCalendar({ isDelayed: false, isOverlayClicked: true });
				}, CALENDAR_CLOSE_TIME);
			}
		};

		if (node) {
			window.addEventListener('keydown', handleKeyPress);

			return () => {
				window.removeEventListener('keydown', handleKeyPress);
			};
		}
	}, [node]);

	useEffect(() => {
		if (minInventoryMap)
			setTimeout(() => {
				setCalendarOpening(false);
			}, OVERLAY_DISABLED_TIME);
	}, [minInventoryMap]);

	useEffect(() => {
		if (inventoryData) {
			computeMinInventoryMap();
		}
	}, [computeMinInventoryMap, inventoryData]);

	// remove window scroll when mobile calendar is open
	useEffect(() => {
		if (typeof window === 'undefined' || allowPageScroll || isDesktop)
			return;
		const body = document.querySelector('body');
		if (body) body.style.overflow = 'hidden';
		return () => {
			if (body && !allowPageScroll) body.style.overflow = 'auto';
		};
	}, [isDesktop]);

	useEffect(() => {
		if (isDesktop && shouldScrollIntoView && node) {
			(node as any).scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}, [isDesktop, node, shouldScrollIntoView]);

	const [isVisibilityTracked, setTrackVisibility] = useState(false);

	useEffect(() => {
		if (!isVisibilityTracked && minInventoryMap && isSvg) {
			trackEvent({
				eventName: ANALYTICS_EVENTS.SEATMAP.CALENDAR_VIEWED,
				[ANALYTICS_PROPERTIES.TGID]: Number(id),
				[ANALYTICS_PROPERTIES.FLOW_TYPE]: getAnalyticsFlowType(product),
				[ANALYTICS_PROPERTIES.MB_NAME]:
					name === 'Headout' ? null : name,
				[ANALYTICS_PROPERTIES.MONTHS_SHOWN]: inventoryListsMap.size,
			});
			setTrackVisibility(true);
		}
	}, [minInventoryMap]);

	if (!minInventoryMap)
		return isSeatmap || !showLoader ? null : (
			<CalendarLoader
				selfRef={getNode}
				wrapperClass={'calendar-main-wrapper'}
				isSvg={isSvg}
			/>
		);

	const medianPrice = getMedianPrice();
	const { totalInvs, maxInvInAMonth } = getInventoryStats();
	calendarType = getCalendarType(totalInvs, maxInvInAMonth);
	const inventoryListsMap = getMonthInvLists(calendarType);
	let { inventoryMap } = inventoryData;
	const {
		query: { lang },
	} = router;
	const propsObj = {
		id,
		node,
		type: calendarType,
		medianPrice,
		inventoryListsMap,
		inventoryMap,
		selfRef: getNode,
		onDateSelected,
		wrapperClass: 'calendar-main-wrapper',
		hidePrice,
		sendCalendarOpenedEvent,
		sendNextClickedEvent,
		isSvg,
		selectedDate: tourId ? selectedTourDate : selectedDate,
		isStaticPositioned,
		isLtt,
		showTimeSlots,
		pricing,
		booking,
		afterDateChange,
		onFetchPricing,
		lang,
		animateOpening,
		animateClosing,
		isCalendarClosing,
		animateFromZero,
		showOverlay,
		routeToBookingFlow: routeToBookingFlowOnTimeSlotClicked,
		onClose,
		highlightSelectedDate,
		showDiscountStars: isSeatmap,
		isSvgCalendar,
	};
	if (hasCustomDateChange || isSingleCalendar) {
		return <RegularCalendarForBroadway {...propsObj} />;
	}
	if (isDesktop) {
		return getDesktopComponent(calendarType, propsObj);
	}

	return <MobileCalendar {...propsObj} />;
};

const mapStateToProps = (state: any, ownProps: any) => {
	const { id, tourId, isCrossSell } = ownProps;
	const inventoryData = getCalendarInventories(state, id, tourId);
	const booking = getBooking(state, id, isCrossSell);
	const selectedDate = ownProps?.selectedDate ?? getSelectedDate(state, id);
	const currentPage = getCurrentPage(state);
	const product = getProduct(state, id);
	const pricing = getPricing(state, id);
	const domainConfig = getDomainConfig(state);

	return {
		inventoryData,
		booking,
		selectedDate,
		currentPage,
		product,
		pricing,
		domainConfig,
	};
};

const mapDispatchToProps = {
	changeBookingDate: setBookingDate,
	onTourChange: setBookingTourId,
	onVariantChange: setBookingVariantId,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Calendar),
);
