import { ActionTypes } from 'Actions/actions';
import type {
	TReviewActionCityParam,
	TReviewActionCollectionParam,
	TReviewActionPersonaParam,
	TReviewActionTGIDParam,
	TReviewCityCategoryParam,
	TReviewCitySubCategoryParam,
} from 'ReduxTypes/review';

export const requestReviewsByTgid = ({
	tgId,
	filterType,
}: TReviewActionTGIDParam) => ({
	payload: { tgId, filterType },
	type: ActionTypes.REQUEST_REVIEWS_BY_TGID,
});

export const receiveReviewsByTgid = ({
	tgId,
	filterType,
	reviews,
}: TReviewActionTGIDParam) => ({
	payload: { tgId, filterType, reviews },
	type: ActionTypes.RECEIVE_REVIEWS_BY_TGID,
});

export const requestReviewsByCollection = ({
	collectionId,
}: Omit<TReviewActionCollectionParam, 'reviews'>) => ({
	payload: {
		collectionId,
	},
	type: ActionTypes.REQUEST_REVIEWS_BY_COLLECTION,
});

export const receiveReviewsByCollection = ({
	collectionId,
	reviewsData,
}: TReviewActionCollectionParam) => ({
	payload: {
		collectionId,
		reviewsData,
	},
	type: ActionTypes.RECEIVE_REVIEWS_BY_COLLECTION,
});

export const appendReviewsByCollection = ({
	collectionId,
	reviewsData,
}: TReviewActionCollectionParam) => ({
	payload: {
		collectionId,
		reviewsData,
	},
	type: ActionTypes.APPEND_REVIEWS_BY_COLLECTION,
});

export const requestReviewsByCity = ({
	city,
}: Omit<TReviewActionCityParam, 'reviews'>) => ({
	payload: {
		city,
	},
	type: ActionTypes.REQUEST_REVIEWS_BY_CITY,
});

export const receiveReviewsByCity = ({
	city,
	reviewsData,
}: TReviewActionCityParam) => ({
	payload: {
		city,
		reviewsData,
	},
	type: ActionTypes.RECEIVE_REVIEWS_BY_CITY,
});

export const appendReviewsByCity = ({
	city,
	reviewsData,
}: TReviewActionCityParam) => ({
	payload: {
		city,
		reviewsData,
	},
	type: ActionTypes.APPEND_REVIEWS_BY_CITY,
});

export const requestReviewsByPersona = (
	params: Omit<TReviewActionPersonaParam, 'reviews'>,
) => ({
	payload: params,
	type: ActionTypes.REQUEST_REVIEWS_BY_PERSONA,
});

export const receiveReviewsByPersona = (params: TReviewActionPersonaParam) => ({
	payload: params,
	type: ActionTypes.RECEIVE_REVIEWS_BY_PERSONA,
});

export const requestReviewsByCityAndCategory = (
	params: TReviewCityCategoryParam,
) => ({
	payload: params,
	type: ActionTypes.REQUEST_REVIEWS_BY_CITY_AND_CATEGORY,
});

export const receiveReviewsByCityAndCategory = (
	params: TReviewCityCategoryParam,
) => ({
	payload: params,
	type: ActionTypes.RECEIVE_REVIEWS_BY_CITY_AND_CATEGORY,
});

export const requestReviewsByCityAndSubCategory = (
	params: TReviewCitySubCategoryParam,
) => ({
	payload: params,
	type: ActionTypes.REQUEST_REVIEWS_BY_CITY_AND_SUBCATEGORY,
});

export const receiveReviewsByCityAndSubCategory = (
	params: TReviewCitySubCategoryParam,
) => ({
	payload: params,
	type: ActionTypes.RECEIVE_REVIEWS_BY_CITY_AND_SUBCATEGORY,
});
