import React, { useCallback, useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';

import type { TSwiperRefActions } from '@headout/espeon/components';

import Conditional from 'Components/common/conditional';
import Image from 'Components/common/image';
import Modal from 'Components/common/modal';
import RenderOneOf from 'Components/common/renderOneOf';
import NavigationButtons from 'Components/desktop/navigationButtons';

import { useSwiperArrows } from 'Hooks/useSwiperArrows';
import { trackEvent } from 'Utils/analytics';
import { onEnterKeyPress } from 'Utils/gen';
import { getImgixImageUrlFixed, sanitiseImageUrl } from 'Utils/imageUtils';

import { ANALYTICS_PROPERTIES } from 'Constants/analytics';
import { strings } from 'Constants/strings';

import type {
	TCarouselItemProps,
	TProps,
	TReviewMediaMappedToReview,
} from './interface';
import {
	MobileCarousel,
	ModalContainer,
	ModalStoryContainer,
	StyledContainer,
	StyledHeading,
	StyledHeadingWrapper,
	StyledImage,
} from './style';

const Swiper = dynamic(() =>
	import(/* webpackChunkName: "Swiper" */ '@headout/espeon/components').then(
		v => v.Swiper,
	),
);

const MultiSlideStoryCarousel = dynamic(
	() =>
		import(
			/* webpackChunkName: "MultiSlideStoryCarousel" */ 'Components/common/multiSlideStoryCarousel'
		),
);

const REVIEW_BUFFER = 5;
export const ITEM_BUFFER = 8;

const CarouselItem = ({
	isLoaded,
	onClick,
	onLoad,
	fileName,
	height,
	width,
	url,
	reviewIndex,
	mediaIndex,
}: TCarouselItemProps) => {
	return (
		<StyledImage
			onClick={e => {
				onClick();
				(e.target as HTMLElement).blur();
			}}
			onKeyDown={e => {
				onEnterKeyPress(e, () => {
					onClick();
					(e.target as HTMLElement).blur();
				});
			}}
			$width={width}
			$height={height}
			className='traveler-media'
			data-index={reviewIndex}
			data-media-index={mediaIndex}
			$showShimmer={!isLoaded}
			tabIndex={0}
		>
			<Image
				src={url}
				width={width}
				height={height}
				alt={fileName}
				onLoad={onLoad}
				enhance
			/>
		</StyledImage>
	);
};

const TravelerMedia = ({
	fetchReviews,
	reviews,
	isDesktop,
	total,
	nextOffset,
	isFetching,
	tgid,
	lang = 'en',
}: TProps) => {
	const [reviewMedias, setReviewMedias] = useState<
		TReviewMediaMappedToReview[]
	>([]);

	const [reviewIndex, setReviewIndex] = useState<number>(-1);
	const [mediaIndex, setMediaIndex] = useState<number>();
	const [showStoryCarousel, setShowStoryCarousel] = useState(false);
	const [lastObserved, setLastObserved] = useState(-1);
	const [shouldFetch, toggleFetchTrigger] = useState(false);
	const [currentLanguage, changeCurrentLanguageTo] = useState(lang);
	const [isLoaded, setIsLoaded] = useState<boolean[]>([]);

	const observer = useRef<IntersectionObserver | null>(null);
	const mediaCarouselRef = useRef<TSwiperRefActions>(null);
	const { showLeftArrow, showRightArrow, onSlideChanged } = useSwiperArrows();
	const imageHeight = isDesktop ? 240 : 130;
	const imageWidth = isDesktop ? 180 : 98;

	const generalReviewFetchConditions = useCallback(
		(condition?: boolean) =>
			reviews &&
			(reviewIndex >= reviews.length - REVIEW_BUFFER || condition) &&
			nextOffset &&
			total &&
			reviews.length < total &&
			nextOffset < total &&
			!isFetching &&
			fetchReviews &&
			tgid,
		[
			reviews,
			reviewIndex,
			nextOffset,
			total,
			isFetching,
			fetchReviews,
			tgid,
		],
	);

	const fetchReviewsIfPossible = useCallback(
		(condition?: boolean) => {
			if (!generalReviewFetchConditions(condition)) return;
			const limit = Math.min(total! - nextOffset!, ITEM_BUFFER);
			fetchReviews!(tgid!, nextOffset, limit);
		},
		[nextOffset, total, generalReviewFetchConditions],
	);

	useEffect(() => {
		if (shouldFetch) fetchReviewsIfPossible(true);
		toggleFetchTrigger(false);
	}, [shouldFetch, fetchReviewsIfPossible]);

	useEffect(() => {
		if (!reviews) return;

		const reviewMediaMapping: TReviewMediaMappedToReview[] = [];
		const currentIsLoaded = [...isLoaded];
		reviews.forEach((review, i) => {
			review.reviewMedias.forEach((media, j) => {
				reviewMediaMapping.push({
					...media,
					url: getImgixImageUrlFixed({
						url: sanitiseImageUrl({ url: media.url }),
						height: imageHeight,
						width: imageWidth,
					}),
					reviewIndex: i,
					mediaIndex: j,
				});
				if (reviewMediaMapping.length > isLoaded.length)
					currentIsLoaded.push(false);
			});
		});

		if (reviewMediaMapping.length >= 2) {
			setReviewMedias(reviewMediaMapping);
			setIsLoaded(currentIsLoaded);
		}
	}, [reviews]);

	const attachObserver = useCallback(() => {
		if (
			!reviewMedias.length ||
			nextOffset === total ||
			reviews?.length === total
		)
			return;

		const watchIndex = Math.max(reviewMedias.length - ITEM_BUFFER, 0);
		if (lastObserved >= watchIndex) return;
		if (lastObserved !== -1 && observer) {
			observer.current?.disconnect();
		}

		const { mediaIndex, reviewIndex } = reviewMedias[watchIndex];

		const watchElement = document.querySelector(
			`.traveler-media[data-index='${reviewIndex}'][data-media-index='${mediaIndex}']`,
		);

		if (watchElement) {
			const currentObserver =
				observer.current ??
				new IntersectionObserver(([entry]) => {
					if (entry.isIntersecting) toggleFetchTrigger(!shouldFetch);
				});
			currentObserver?.disconnect();
			currentObserver?.observe(watchElement);
			observer.current ??= currentObserver;
			setLastObserved(watchIndex);
		}
	}, [reviewMedias, lastObserved]);

	useEffect(() => {
		attachObserver();
	}, [attachObserver]);

	useEffect(() => {
		return () => {
			if (!observer.current || lastObserved === -1) return;
			observer.current.disconnect();
		};
	}, []);

	useEffect(() => {
		if (lang !== currentLanguage) {
			setLastObserved(-1);
			changeCurrentLanguageTo(lang);
		}
	}, [lang, currentLanguage]);

	const selectMedia = (
		reviewIndex: number,
		mediaIndex: number,
		netIndex: number,
	) => {
		setMediaIndex(mediaIndex);
		setReviewIndex(reviewIndex);
		setShowStoryCarousel(true);
		if (
			reviewMedias.length > REVIEW_BUFFER &&
			netIndex >= reviewMedias.length - REVIEW_BUFFER
		)
			fetchReviewsIfPossible();
		trackEvent({
			eventName: 'Guest Snapshot Clicked',
			ranking: netIndex + 1,
		});
		const { rating } = reviews![reviewIndex];
		trackEvent({
			eventName: 'Story Mode Opened',
			[ANALYTICS_PROPERTIES.RATING]: rating,
		});
	};

	const onUpdateMediaIndex = () => {
		const { rating } = reviews![reviewIndex];
		if (rating)
			trackEvent({
				eventName: 'Reviews Media Previewed',
				[ANALYTICS_PROPERTIES.RATING]: rating,
			});
		fetchReviewsIfPossible();
	};

	if (!reviewMedias || !reviews || reviewMedias.length <= 1 || !total)
		return null;

	const onImageLoad = (index: number) => {
		setIsLoaded(currentIsLoaded =>
			currentIsLoaded.map((v, ind) => (ind === index ? true : v)),
		);
	};

	const carouselItems = reviewMedias.map(
		({ url, reviewIndex, mediaIndex, fileName }, index) => (
			<CarouselItem
				key={`traveler-media-tiles-${reviewIndex}-${mediaIndex}`}
				url={url}
				height={imageHeight}
				width={imageWidth}
				fileName={fileName}
				onClick={() => {
					if (isLoaded[index])
						selectMedia(reviewIndex, mediaIndex, index);
				}}
				isLoaded={isLoaded[index]}
				reviewIndex={reviewIndex}
				mediaIndex={mediaIndex}
				onLoad={() => onImageLoad(index)}
			/>
		),
	);

	return (
		<StyledContainer>
			<StyledHeadingWrapper>
				<StyledHeading>{strings.TRAVELER_MEDIA}</StyledHeading>
				<Conditional if={carouselItems?.length > 4 && isDesktop}>
					<div className='navigation-buttons-container'>
						<NavigationButtons
							showLeftArrow={showLeftArrow}
							showRightArrow={showRightArrow}
							prevSlide={mediaCarouselRef.current?.prevSlide}
							nextSlide={mediaCarouselRef.current?.nextSlide}
							size='small'
						/>
					</div>
				</Conditional>
			</StyledHeadingWrapper>
			<RenderOneOf
				positionalConditions={[Boolean(isDesktop), !isDesktop]}
			>
				<Swiper
					slidesToScrollBy={4}
					slidesToShow={4}
					swiperRef={mediaCarouselRef}
					onSlideChanged={params => {
						onSlideChanged(params);
						attachObserver();
					}}
					isMobile={!isDesktop}
				>
					{carouselItems}
				</Swiper>
				<MobileCarousel>{carouselItems}</MobileCarousel>
			</RenderOneOf>

			<Conditional if={reviewIndex !== -1}>
				<ModalContainer>
					<Modal
						open={showStoryCarousel}
						containerClassName={'story-modal-container'}
						contentClassName={'core-modal-content'}
						onRequestClose={() => {
							setShowStoryCarousel(false);
							trackEvent({
								eventName: 'Story Mode Closed',
							});
						}}
						// @ts-expect-error TS(2769): No overload matches this call.
						dimensionsStyle={{
							marginTop: '6.25rem',
							marginBottom: '6.25rem',
						}}
					>
						<ModalStoryContainer>
							<MultiSlideStoryCarousel
								reviewIndex={reviewIndex}
								mediaIndex={mediaIndex ?? 0}
								reviews={reviews}
								onUpdateMediaIndex={onUpdateMediaIndex}
								onUpdateReviewIndex={setReviewIndex}
								isDesktop={isDesktop}
								hideCarousel={() => setShowStoryCarousel(false)}
							/>
						</ModalStoryContainer>
					</Modal>
				</ModalContainer>
			</Conditional>
		</StyledContainer>
	);
};

export default TravelerMedia;
