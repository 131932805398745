import type { RootStateOrAny } from 'react-redux';
import type { customUserFieldType } from 'Models/customField';
import cookies from 'nookies';
import type { Dispatch } from 'redux';

import { notify } from 'Components/common/notify';

import { validateLiveInventoryFromCache } from 'Utils/bookingFlowUtils';
import { sendException } from 'Utils/coralogix/log';
import {
	getApiCurrencyParameter,
	getApiLanguageParameter,
	getBaseRequestOptions,
} from 'Utils/fetchUtils';
import fetch from 'Utils/fetchWrapper';
import { checkIsLivePricingEnabled } from 'Utils/livePricingUtils';
import { error } from 'Utils/logUtils';
import {
	getPriceFetchingStatusForDate,
	getPriceStoreStatus,
} from 'Utils/pricingUtils';
import {
	getCurrenciesMap,
	getCurrentCityTimeZone,
	getCurrentCurrency,
	getCurrentLanguageCode,
	getProduct,
	getProductVariants,
} from 'Utils/stateUtils';
import { getApiBaseUrl, getApiCDNBaseUrl } from 'Utils/urlUtils';

import {
	receivePricingForRange,
	recieveUserFields,
	requestPricingForRange,
} from 'Actions/pricing';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';

import { PAGE_TYPE, PRICE_FETCH_STATUS } from 'Constants/constants';

import { fetchSeatingSVG } from './seatingSVG';

export const fetchPricingForRange =
	({
		productId,
		lang = 'en',
		req,
		variantId,
		paxSelections,
		fromDate = null,
		toDate = null,
		selectedDate,
		flushExistingInventories = false,
		shouldSkipEarlyReturn = false,
		shouldFetchLiveInventory = false,
	}: {
		productId: string | number;
		lang?: string;
		req?: any;
		variantId?: string | number;
		paxSelections?: any;
		fromDate?: string | null;
		toDate?: string | null;
		selectedDate?: string;
		flushExistingInventories?: boolean;
		shouldSkipEarlyReturn?: boolean;
		shouldFetchLiveInventory?: boolean;
	}) =>
	(dispatch: any, getState: any) => {
		const state = getState();
		const fetchStatus = getPriceFetchingStatusForDate({
			status: getPriceStoreStatus(state),
			date: selectedDate,
			productId,
		});

		if (
			!flushExistingInventories &&
			fetchStatus !== PRICE_FETCH_STATUS.NOT_FETCHED &&
			!shouldSkipEarlyReturn
		)
			return;

		if (!flushExistingInventories || shouldSkipEarlyReturn)
			dispatch(requestPricingForRange({ productId, fromDate, toDate }));
		const currentCurrencyCode = getCurrentCurrency(getState());
		const product = getProduct(state, productId);
		const currencyParam = getApiCurrencyParameter(
			getState(),
			currentCurrencyCode,
		);
		const langParam = getApiLanguageParameter(lang);
		const variants = getProductVariants({ productId, state: getState() });

		const currenciesMap = getCurrenciesMap(getState());

		const inventoryTimeZone = getCurrentCityTimeZone(getState());
		const { livePricingSupported, liveInventoryCheck } = product || {};
		const isLivePricingEnabled = checkIsLivePricingEnabled(
			PAGE_TYPE.CHECKOUT,
			livePricingSupported,
		);

		let forceFetchInventoryFromDB = false;

		if (
			typeof window !== 'undefined' &&
			!shouldFetchLiveInventory &&
			liveInventoryCheck
		) {
			const Cookies = cookies.get();
			forceFetchInventoryFromDB = validateLiveInventoryFromCache(
				Cookies,
				`${productId}`,
			);
		}

		const fromDateParam = fromDate ? `&from-date=${fromDate}` : '';
		const toDateParam = toDate ? `&to-date=${toDate}` : '';
		const variantIdParam = variantId ? `&variantId=${variantId}` : '';
		const paxSelectionsParam = paxSelections
			? `&paxSelections=${JSON.stringify(paxSelections)}`
			: '';
		const hostName =
			isLivePricingEnabled ||
			shouldFetchLiveInventory ||
			forceFetchInventoryFromDB
				? getApiBaseUrl()
				: getApiCDNBaseUrl({
						state: getState(),
				  });

		const url = `${hostName}/api/v7/tour-groups/${productId}/inventories/?${langParam}${fromDateParam}${toDateParam}${currencyParam}${variantIdParam}${paxSelectionsParam}`;
		const requestOptions = req
			? { headers: { cookie: req.headers.cookie } }
			: {};
		const options = getBaseRequestOptions(getState(), requestOptions);

		return fetch(url, options)
			.then(response => response.json())
			.then(async pricingJson => {
				dispatch(
					receivePricingForRange({
						productId,
						variantId,
						pricingJSON: pricingJson,
						inventoryTimeZone,
						variants,
						currenciesMap,
						flushExistingInventories,
					}),
				);
				if (pricingJson.seatingChartUpload) {
					await dispatch(
						fetchSeatingSVG({
							id: productId,
							svgUrl: pricingJson.seatingChartUpload.url,
						}),
					);
				}
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				notify.showNetworkError(err);
			});
	};

export const fetchAndSetCustomFieldsToPricingStore = async ({
	state,
	dispatch,
	id,
}: {
	state: RootStateOrAny;
	id: string;
	dispatch: Dispatch<any>;
}) => {
	try {
		const hostName = getApiCDNBaseUrl({
			state,
		});
		const language = getCurrentLanguageCode(state) || 'en';
		const languageParam = getApiLanguageParameter(language);
		const url = `${hostName}/api/v1/user-field/tour-group/${id}?${languageParam}`;
		const response = await fetch(url);
		const userFields = await response.json();

		dispatch(
			recieveUserFields({
				productId: id,
				userFields,
			}),
		);
	} catch (e) {
		sendException({
			exception: 'user-field-tour-group api exception',
			hint: {
				extra: {
					id,
					error: e,
				},
			},
			methodName: 'fetchAndSetCustomFieldsToPricingStore',
		});
	}
};

export type TUserFieldsAPIResponse = Record<
	string,
	Record<
		string,
		Record<
			string,
			{
				defaultUserFields: customUserFieldType[];
				vendorLevelUserFields: customUserFieldType[];
			}
		>
	>
>;

// TODO: move this function to somewhere more appropriate since this is not a thunk or (directly) related to pricing
export const fetchBulkUserFields = async (
	productIds: (number | string)[],
	state: RootStateOrAny,
) => {
	const hostName = getApiCDNBaseUrl({
		state,
	});

	const language = getCurrentLanguageCode(state) || 'en';

	const languageParam = getApiLanguageParameter(language);

	const url = `${hostName}/api/v1/user-field/?tourGroupIds[]=${productIds.join(
		',',
	)}&${languageParam}`;

	const response = await fetch(url);

	const userFieldsResponse: TUserFieldsAPIResponse = await response.json();

	return userFieldsResponse;
};
