import { useCallback, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
/* eslint-disable-next-line no-restricted-imports */
import styled, { css } from 'styled-components';

import { Button } from '@headout/eevee';
import { css as pixiecss } from '@headout/pixie/css';

import Image from 'Components/common/image';
import type { GalleryType } from 'Components/mobile/productBannerCarousel/interface';

import { ImageSvg } from 'Assets/commonSvgs';

import { getThumbnailFromYTVideoUrl } from 'Utils/productUtils';

import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';

const PlayButton = dynamic(
	() =>
		import(
			/* webpackChunkName: 'PlayButton' */ 'Components/common/video/playButton'
		),
);
const PhotoGallery = dynamic(
	() =>
		import(
			/* webpackChunkName: 'PhotoGallery' */ 'Components/desktop/photoGallery'
		),
	{
		ssr: false,
	},
);

const MediaContainer = styled.div<{ $isDualLayout: boolean }>`
	display: flex;
	justify-content: space-between;
	flex-wrap: row-wrap;
	gap: 0.5rem;
	position: relative;

	${({ $isDualLayout }) =>
		css`
			height: ${$isDualLayout ? 23.25 : 23.4375}rem;
		`}
`;

const LeftMediaContainer = styled.div<{
	$isDualLayout: boolean;
	$isVideo?: boolean;
}>`
	position: relative;

	${({ $isDualLayout }) =>
		css`
			width: ${$isDualLayout ? 37.25 : 37.5}rem;
		`}

	.banner-media {
		transition: filter 0.3s;
		height: 100% !important;
		border-radius: 1rem 0 0 1rem;
		${({ $isVideo }) =>
			$isVideo ? `background: ${colors.BLACK}` : 'object-fit: cover'};
	}

	img:hover {
		cursor: pointer;
		filter: brightness(80%);
	}
`;

const RightColumnImages = styled.div<{
	$isSingleImage: boolean;
}>`
	${({ $isSingleImage }) =>
		css`
			width: ${$isSingleImage ? 37.25 : 37}rem;
		`}

	display: ${({ $isSingleImage }) => ($isSingleImage ? 'block' : 'grid')};
	grid-template-columns: repeat(2, 1fr);
	gap: 0.5rem;

	.media-item {
		transition: filter 0.3s;
		cursor: pointer;

		&:hover {
			filter: brightness(80%);
		}

		height: ${({ $isSingleImage }) =>
			$isSingleImage ? '100%' : `calc((23.4375rem / 2) - 0.25rem)`};

		& img {
			height: 100% !important;
			width: 100%;
			object-fit: cover;
			${({ $isSingleImage }) =>
				$isSingleImage && 'border-radius: 0 1rem 1rem 0'};
		}

		&:nth-child(2) > img {
			border-top-right-radius: 1rem;
		}

		&:nth-child(4) > img {
			border-bottom-right-radius: 1rem;
		}
	}
`;

const viewAllStyles = pixiecss({
	position: 'absolute',
	marginTop: 'space.12',
	right: 'space.12',
});

type Media = {
	url: string;
	alt: string;
	title?: string;
	credit?: string;
};

type Review = {
	reviewerImageUrl: string;
	nonCustomerName: string;
	reviewTime: string;
};

type MediaBannerProps = {
	productName: string;
	imageUploads: Media[];
	reviews: Review[];
	experienceVideo: Media;
	onGalleryChange?: (galleryType: GalleryType) => void;
	onGalleryMediaClick?: (galleryType: GalleryType, isVideo: boolean) => void;
};

const TAB_MAP = {
	gallery: 'Headout Gallery',
	reviews: 'Guest Snapshots',
} as const;

const MultiMediaBanner = ({
	productName,
	imageUploads,
	reviews,
	experienceVideo,
	onGalleryChange,
	onGalleryMediaClick,
}: MediaBannerProps) => {
	const [initialIndex, setInitialIndex] = useState(0);
	const [galleryMedia, setGalleryMedia] = useState(imageUploads);
	const [showGallery, setShowGallery] = useState(false);

	const handleShowGallery = (index: number) => {
		onGalleryChange?.(TAB_MAP.gallery);
		setInitialIndex(index);
		setShowGallery(true);
	};

	const hasVideo = !!experienceVideo?.url;

	const renderRightImages = useCallback(() => {
		if (imageUploads.length >= 5) {
			const indexStart = 1,
				indexEnd = 5;
			return imageUploads
				.slice(indexStart, indexEnd)
				.map((image, index) => (
					<div key={index} className='media-item'>
						<Image
							src={image?.url}
							alt={image?.alt}
							width={292}
							height={183}
							onClick={() =>
								handleShowGallery(index + (hasVideo ? +2 : 1))
							}
						/>
					</div>
				));
		}

		return (
			<div className='media-item'>
				<Image
					src={imageUploads[1]?.url}
					alt={imageUploads[1]?.alt}
					width={596}
					height={372}
					onClick={() => handleShowGallery(hasVideo ? 2 : 1)}
				/>
			</div>
		);
	}, [imageUploads]);

	useEffect(() => {
		if (experienceVideo?.url) {
			const youtubeUrl = experienceVideo.url;
			const thumbnailUrl = getThumbnailFromYTVideoUrl(youtubeUrl);

			const video = {
				url: thumbnailUrl,
				alt: 'Youtube Video',
				youtubeUrl,
			};

			setGalleryMedia([video, ...galleryMedia]);
		}
	}, [experienceVideo?.url]);

	return (
		<>
			<MediaContainer
				className='media-container'
				$isDualLayout={imageUploads.length < 5}
			>
				<LeftMediaContainer
					className='media-container-item'
					$isVideo={hasVideo}
					$isDualLayout={imageUploads.length < 5}
				>
					<Image
						priority
						className='banner-media'
						src={imageUploads[0]?.url}
						alt={imageUploads[0]?.alt}
						width={620}
						height={387.5}
						onClick={() => handleShowGallery(0)}
					/>
					{hasVideo && (
						<PlayButton onClick={() => handleShowGallery(0)} />
					)}
				</LeftMediaContainer>

				<RightColumnImages
					className='media-container-item'
					$isSingleImage={imageUploads.length < 5}
				>
					{renderRightImages()}
				</RightColumnImages>

				<div className={viewAllStyles}>
					<Button
						as={'button'}
						size={'small'}
						state={'default'}
						btnType={'white'}
						variant={'primary'}
						icon={<ImageSvg width={'16'} height={'16'} />}
						primaryText={strings.VIEW_ALL_IMAGES}
						onClick={() => handleShowGallery(0)}
						data-qa-marker={'view-all-images-button'}
						data-qa-id={'view-all-images-button'}
					/>
				</div>
			</MediaContainer>

			<PhotoGallery
				productName={productName}
				showGallery={showGallery}
				reviews={reviews || []}
				imageUploads={galleryMedia}
				initialIndex={initialIndex}
				onClose={() => setShowGallery(false)}
				onTabClick={tabState => {
					const galleryType = TAB_MAP[tabState];
					onGalleryChange?.(galleryType as GalleryType);
				}}
				onImagePreviewClick={(tabState, index) => {
					const galleryType = TAB_MAP[tabState];
					onGalleryMediaClick?.(
						galleryType as GalleryType,
						hasVideo && index === 0,
					);
				}}
			/>
		</>
	);
};

export default MultiMediaBanner;
