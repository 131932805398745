/* eslint-disable react/display-name */

import React from 'react';
import dynamic from 'next/dynamic';
import cookie from 'nookies';
import Error from 'pages/_error';

import { renderError } from 'Server/errors';

import ProductPageBase, {
	connectProductPage,
} from 'Containers/common/productPage';

import { BrandLoader } from 'Components/common/brandLoader';
import embedStructuredData from 'Components/common/embedStructuredData';

import {
	getExperiencePageEventCommonProperties,
	sendVariableToDataLayer,
	trackPageView,
} from 'Utils/analytics';
import { isTourGroupOpenDated } from 'Utils/bookingFlowUtils';
import { getCategoriesAndSubCategoriesInfo } from 'Utils/collectionsUtils';
import { isServer } from 'Utils/envUtils';
import fetch from 'Utils/fetchWrapper';
import { saveEntitiesToLocalStorage } from 'Utils/localStorageUtils';
import PlatformUtils from 'Utils/platformUtils';
import {
	getProductMetaDescription,
	getProductMetaTags,
	getProductPageTitle,
	getSiteMapMetaData,
	hasMultipleAvailableVariants,
	isNoIndex,
} from 'Utils/productUtils';
import { redirectTo } from 'Utils/redirectUtils';
import { getProduct, getSlots } from 'Utils/stateUtils';
import {
	getApiCDNBaseUrlV2,
	getApiString,
	getBaseUrl,
	getBookingLandingStageUrl,
	getCurrentUrl,
	getLocationObjectFromRouter,
	isNumericProductId,
} from 'Utils/urlUtils';

import { fetchCategoriesByCityCode } from 'Thunks/categories';
import { fetchNearByCities } from 'Thunks/city';
import { fetchCollections } from 'Thunks/collections';
import { fetchPoiInfo } from 'Thunks/poi';
import { fetchProduct } from 'Thunks/product';
import { fetchReviewsByTgid } from 'Thunks/review';
import { fetchItineraryData } from 'Thunks/routesItinerary';
import { fetchSimilarProducts } from 'Thunks/similarProducts';
import { fetchSlots } from 'Thunks/slots';
import { resetBookingStore } from 'Actions/booking';
import { changeCity } from 'Actions/city';
import { changePage } from 'Actions/page';
import { resetSlots } from 'Actions/slots';

import { ANALYTICS_PROPERTIES } from 'Constants/analytics';
import {
	ANALYTICS_FLOW_TYPE,
	PAGE_TYPE,
	RECENTLY_VIEWED_ENTITIES,
	SEARCH_ENTITY_TYPE,
	TOUR_TYPE,
} from 'Constants/constants';
import { strings } from 'Constants/strings';

const ProductPageDesktop = dynamic(
	() => import('Containers/desktop/productPage'),
	{
		loading: () => <BrandLoader isLoading />,
	},
);
const ProductPageMobile = dynamic(
	() => import('Containers/mobile/productPage'),
	{
		loading: () => <BrandLoader isLoading />,
	},
);

class ProductPageElements extends ProductPageBase {
	static getMetaScript: any;

	static getInitialProps: any;

	componentDidMount() {
		// @ts-expect-error TS(2339): Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message
		const { product, lang, error, currentCityCode, query, currentCity } =
			this.props;

		if (error) return;

		super.componentDidMount();

		const { id, name, urlSlugs, imageUploads, experienceVideo } = product;

		sendVariableToDataLayer({
			name: ANALYTICS_PROPERTIES.IS_VIDEO_PRESENT,
			value: !!experienceVideo?.url,
		});

		let flowType;

		const isOpenDated = isTourGroupOpenDated(product);

		if (isOpenDated) {
			flowType = hasMultipleAvailableVariants(product)
				? ANALYTICS_FLOW_TYPE.OPEN_DATED_MULTI_VARIANT
				: ANALYTICS_FLOW_TYPE.OPEN_DATED_NO_VARIANT;
		}

		const isFlexibleCancellationProduct = product.insuranceConfigs?.length;

		trackPageView({
			pageType: PAGE_TYPE.EXPERIENCE,
			...getExperiencePageEventCommonProperties(product, currentCityCode),
			...(isOpenDated && { 'Flow Type': flowType }),
			[ANALYTICS_PROPERTIES.EXPERIENCE_CONTENT_TYPE]:
				product.contentMachineTranslated ? 'MT' : 'HT',
			...(query?.clusterId !== null &&
				query?.clusterId !== undefined && {
					'Cluster ID': query.clusterId,
				}),
			[ANALYTICS_PROPERTIES.FLEXIBLE_CANCELLATION]:
				isFlexibleCancellationProduct ? 'True' : 'False',
		});

		const {
			cityCode,
			displayName: cityDisplayName,
			country,
			urlSlugs: cityUrlSlugs,
		} = currentCity;

		const recentlyViewedProductObj = {
			id,
			pageAsPath: urlSlugs?.[lang ? lang.toUpperCase() : 'EN'],
			displayName: name,
			cityCode,
			searchType: SEARCH_ENTITY_TYPE.PRODUCT,
			entityType: SEARCH_ENTITY_TYPE.PRODUCT,
			imageUrl:
				imageUploads && imageUploads?.[0] && imageUploads?.[0]?.url,
			cityDisplayName,
			countryDisplayName: country?.displayName,
			cityPagePath: cityUrlSlugs?.[lang ? lang.toUpperCase() : 'EN'],
		};

		saveEntitiesToLocalStorage({
			key: RECENTLY_VIEWED_ENTITIES,
			value: recentlyViewedProductObj,
		});
	}

	// @ts-expect-error TS(2416): Property 'componentDidUpdate' in type 'ProductPage... Remove this comment to see the full error message
	componentDidUpdate(prevProps, prevState) {
		// @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
		super.componentDidUpdate(prevProps, prevState);
	}

	render() {
		// @ts-expect-error TS(2339): Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message
		const { error, statusCode } = this.props;
		if (error) return <Error statusCode={statusCode} />;

		const isDesktop = PlatformUtils.isDesktop();
		return isDesktop ? (
			<ProductPageDesktop {...this.props} />
		) : (
			<ProductPageMobile {...this.props} />
		);
	}
}

ProductPageElements.getMetaScript = ({
	currentCity,
	product,
	lang,
	slots,
	categoriesAndSubCategoriesInfo,
	localizedStrings,
}: {
	currentCity: any;
	product: any;
	lang: any;
	slots: any;
	categoriesAndSubCategoriesInfo: any;
	localizedStrings: TStaticStringLabels;
}) => {
	const schemaMetaData = getSiteMapMetaData({
		product,
		currentCity,
		lang,
		slots,
		categoriesAndSubCategoriesInfo,
		localizedStrings,
	});
	return embedStructuredData({
		id: 'productPageSchemaScript',
		type: 'application/ld+json',
		scriptAsString: JSON.stringify(schemaMetaData),
	});
};

ProductPageElements.getInitialProps = async (ctx: any) => {
	const { store, query, req, pathname, asPath, res, localizedStrings } = ctx;
	const userAgent = req ? req.headers['user-agent'] : navigator.userAgent;
	const isBot = req
		? req.headers['x-bot'] === 'true'
		: PlatformUtils.isBot(userAgent);
	const allCookies = req ? cookie.get(ctx) : cookie.get();
	const { id, lang, bypassCache, itineraryPreviewId } = query;
	const currentUrl = req ? getCurrentUrl(req) : window.location.href;
	const currentUrlPath = asPath.split('?')[0];
	const location = getLocationObjectFromRouter(query, pathname, asPath);
	const url = `${location.href}${location.search}`;

	if (!isNumericProductId(id)) {
		return renderError(res, 404);
	}

	const apiLang = getApiString(lang);

	if (localizedStrings) {
		strings.setContent({
			default: localizedStrings,
		});
	}

	const cityParam = location.query?.city;
	let isSecondaryCity;

	/* PrePush Server Functions */
	try {
		await fetch(
			`${getApiCDNBaseUrlV2({
				state: store.getState(),
			})}/api/v2/tour-group/basic-info/get/id/${id}`,
		)
			.then(response => response.json())
			.then(json => {
				const {
					city,
					allTags,
					url: sanitizedUrl,
					secondaryCities,
				} = json;

				isSecondaryCity =
					secondaryCities &&
					secondaryCities.length &&
					secondaryCities.includes(cityParam);

				const cityCode = isSecondaryCity ? cityParam : city.code;

				store.dispatch(changeCity({ cityCode }));

				const isOneTimeEvent =
					allTags.indexOf(TOUR_TYPE.ONE_TIME) !== -1;

				if (isOneTimeEvent) {
					const computedUrl = `${getBookingLandingStageUrl(
						sanitizedUrl,
					)}${location.search}`;

					return redirectTo(res, computedUrl);
				}

				const langParam = lang ? `/${lang.toLowerCase()}` : '';
				const computedUrl = `${langParam}${sanitizedUrl}${location.search}`;

				if (computedUrl === url) return Promise.resolve();
			});
	} catch (err) {
		if (err && (err as any).status === 404) {
			return renderError(res, 404);
		}
		return renderError(res, 500);
	}

	const state = store.getState();
	const currentCityCode = state?.city?.currentCityCode;
	const currentCity = state?.city?.citiesMap?.[currentCityCode];
	const {
		latitude: cityLatitude,
		longitude: cityLongitude,
		noIndex: isCityNoIndex,
	} = currentCity || {};

	/* PreRender Server Functions */
	await Promise.all([
		store.dispatch(changePage(PAGE_TYPE.EXPERIENCE)),
		store.dispatch(resetBookingStore({ id })),
		store.dispatch(
			fetchProduct({
				id,
				lang,
				req,
				secondaryCity: isSecondaryCity ? cityParam : '',
				message: `product page - server - ${url}`,
			}),
		),
		// @ts-expect-error TS(2345): Argument of type '{ id: any; lang: any; req: any; ... Remove this comment to see the full error message
		store.dispatch(fetchSimilarProducts({ id, lang, req })),
		store.dispatch(fetchSlots({ id, forDays: 10 })),
		store.dispatch(fetchReviewsByTgid({ tgId: id, lang, limit: 10 })),
		store.dispatch(
			fetchPoiInfo({ tourgroupId: id, operatingSchedules: true }),
		),
		store.dispatch(
			fetchCollections({
				cityCode: currentCityCode,
				lang,
				limit: 12,
			}),
		),
		store.dispatch(
			fetchCategoriesByCityCode({ cityCode: currentCityCode, lang }),
		),
		store.dispatch(
			fetchNearByCities({
				cityLatitude,
				cityLongitude,
				cityCode: currentCityCode,
			}),
		),
		store.dispatch(
			fetchItineraryData({
				id,
				sections: true,
				lang,
				bypassCache: bypassCache === 'true' && !!itineraryPreviewId,
			}),
		),
	]);
	const product = getProduct(store.getState(), id);
	const { urlSlugs, canonicalUrl } = product;
	// In case of multiple nested url and english lang. Lang will be coming wrong.
	const correctLangUrl =
		typeof urlSlugs?.[apiLang] !== 'undefined'
			? urlSlugs?.[apiLang]
			: urlSlugs?.EN;
	if (currentUrlPath !== correctLangUrl) {
		return redirectTo(res, `${correctLangUrl}${location.search}`, 301);
	}
	const getTitle = ({ store, params }: any) =>
		getProductPageTitle(getProduct(store.getState(), params.id));
	const getExperienceUrl = ({ store, params }: any) => {
		const product = getProduct(store.getState(), params.id);
		return `${getBaseUrl()}${product?.urlSlugs?.[apiLang || 'EN']}`;
	};
	const getMetaDescription = ({ store, params }: any) =>
		getProductMetaDescription(getProduct(store.getState(), params.id));
	const getMetaTags = ({ store, params }: any) =>
		getProductMetaTags({
			product: getProduct(store.getState(), params.id),
			paramLang: params.lang,
		});

	const getMetaScript = ({ store, params, localizedStrings }: any) => {
		const product = getProduct(store.getState(), params.id);
		const slots = getSlots(store.getState(), params.id);
		const categoriesAndSubCategoriesInfo =
			getCategoriesAndSubCategoriesInfo(store.getState(), {
				cityCode: currentCityCode,
				lang,
			});

		if (!product || !currentCity) {
			return null;
		}
		return ProductPageElements.getMetaScript({
			currentCity,
			product,
			lang: params.lang,
			slots,
			categoriesAndSubCategoriesInfo,
			localizedStrings,
		});
	};

	const isProductNoIndex = isNoIndex({
		product,
		paramLang: query?.lang || 'en',
		isCityNoIndex,
	});
	const scripts = [getMetaScript({ store, params: query, localizedStrings })];

	// Slots are only used for generating Meta.
	if (isServer()) {
		store.dispatch(resetSlots({ productId: id }));
	}

	return {
		id,
		lang: lang || 'en',
		query,
		location,
		cookies: allCookies,
		helmetFunctions: {
			title: getTitle({ store, params: query }),
			canonicalUrl: canonicalUrl
				? canonicalUrl
				: getExperienceUrl({ store, params: query }),
			metaDescription: getMetaDescription({ store, params: query }),
			metaTagString: getMetaTags({ store, params: query }),
			isNoIndex: isProductNoIndex,
			scripts,
			currentUrl,
		},
		isBot,
	};
};

const ProductPage = connectProductPage(ProductPageElements);

export default ProductPage;
