/* eslint-disable-next-line no-restricted-imports */
import styled, { css } from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

import { RatingContainer as InnerRatingContainer } from '../rating';

type TExpandedProp = {
	$isExpanded?: boolean;
};

const expandedWrapperStyle = css`
	min-width: 17.625rem;
	padding: 1rem 0.75rem;
	border: 1px solid ${colors.GREY_DS.G6};
	border-radius: 8px;
	margin: 0 0.75rem;
	background-color: ${colors.WHITE};
	display: flex;
	flex-direction: column;
	min-height: 21.25rem;
`;

export const ReviewWrapper = styled.div<TExpandedProp>`
	position: relative;
	margin-top: 2rem;

	${({ $isExpanded }) => $isExpanded && expandedWrapperStyle};

	@media (max-width: 768px) {
		max-width: 100%;
		word-break: break-word;
	}
`;

export const ReviewHeaderCompact = styled.div`
	display: flex;
`;

export const ReviewInfo = styled.div`
	flex-grow: 1;
	.reviewer-name {
		${getFontDetailsByLabel(TYPE_LABELS.HEADING_SMALL)};
		margin-left: 0.125rem;
	}
`;

export const ReviewDateTime = styled.p`
	${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)};
	color: ${colors.GREY_DS.G3};

	@media screen and (min-width: 768px) {
		align-self: center;
	}
`;

const expandedReviewerImageStyles = css`
	margin-right: 0.375rem;
	width: 2.25rem;
	height: 2.25rem;

	img {
		width: 2.25rem;
		height: 2.25rem;
	}
`;

export const ReviewerImage = styled.div<TExpandedProp>`
	grid-area: image;
	margin-right: 0.5rem;
	width: 3rem;
	height: 3rem;
	position: relative;

	img {
		width: 3rem;
		height: 3rem;
	}

	${({ $isExpanded }) => $isExpanded && expandedReviewerImageStyles}

	.reviewer-image {
		border-radius: 50%;
		margin: 0 auto;
	}
`;

export const ReviewContentWrapper = styled.div`
	display: inline-block;
	flex: 1;
	word-break: break-word;
	hyphens: auto;
	margin-top: 0.75rem;
`;

export const reviewContentExpanded = css`
	max-height: 10rem;
	${getFontDetailsByLabel(TYPE_LABELS.PARAGRAPH_REGULAR)};
	text-overflow: ellipsis;
`;

export const ReviewContent = styled.div<
	{ readMore?: boolean; $shouldLimitLines?: boolean } & TExpandedProp
>`
	${getFontDetailsByLabel(TYPE_LABELS.PARAGRAPH_LARGE)};
	display: -webkit-box;
	-webkit-line-clamp: ${({ readMore, $shouldLimitLines, $isExpanded }) =>
		$isExpanded ? ($shouldLimitLines ? 6 : 8) : readMore ? 2 : null};
	-webkit-box-orient: vertical;
	overflow: hidden;
	${({ $isExpanded }) => $isExpanded && reviewContentExpanded};
	@media (max-width: 768px) {
		line-height: 1.25rem;
		font-size: 0.875rem;
	}
`;

export const ReadMoreBtn = styled.button`
	${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_LARGE_HEAVY)};
	margin-top: 0.25rem;

	@media (max-width: 768px) {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR_HEAVY)}
	}
`;

export const DesktopCarouselContainer = styled.div`
	max-width: 49.5rem;
	margin: 20px 0 32px 0;
	width: 100%;

	.review-media-carousel {
		border-radius: 0.375rem;

		.arrow-button-list {
			transform: none;
			width: 100%;
		}

		.review-image-container {
			width: 11.25rem;
			height: 15rem;
			margin-right: 1.5rem;
			border-radius: 0.375rem;
			cursor: pointer;

			img {
				width: 11.25rem;
				height: 15rem;
				border-radius: 0.375rem;
			}
		}

		.prev-button-wrapper,
		.next-button-wrapper {
			top: 7.5rem;
		}
	}
`;

export const MobileCarouselContainer = styled.div<TExpandedProp>`
	display: flex;
	margin-top: ${({ $isExpanded }) => ($isExpanded ? 0 : 1)}rem;
	margin-bottom: ${({ $isExpanded }) => ($isExpanded ? 0 : 2)}rem;
`;

const mobileCarouselWrapperExpandedStyles = css`
	min-width: 5rem;
	max-width: 5rem;
	height: 6.625rem;
	margin-right: 0.5rem;

	img {
		cursor: pointer;
	}
`;

export const MobileCarouselWrapper = styled.div<TExpandedProp>`
	min-width: 6.125rem;
	max-width: 6.125rem;
	height: 8.25rem;
	margin-right: 1rem;
	overflow: hidden;
	border-radius: 0.25rem;

	${({ $isExpanded }) => $isExpanded && mobileCarouselWrapperExpandedStyles}

	:last-child {
		margin-right: 0;
		position: relative;
	}
`;

export const MobileCarouselLastSlide = styled.div<TExpandedProp>`
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
	cursor: pointer;
	width: 6.125rem;
	height: 8.25rem;
	margin-right: 1rem;
	overflow: hidden;
	border-radius: 0.25rem;
	color: ${colors.WHITE};
	position: absolute;
	top: 0;
	padding: 0.375rem;

	${({ $isExpanded }) => $isExpanded && mobileCarouselWrapperExpandedStyles}

	.flexContainer {
		display: flex;
		height: 100%;
		align-items: center;
		justify-content: center;
	}

	.balanceImages {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_SMALL)};
	}
`;

const localizeTextExpanded = css`
	${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_SMALL)};
	color: ${colors.BEACH1};
	margin-top: 0.375rem;
	min-height: 1rem;
	text-decoration: underline;
`;

export const LocalizeText = styled.div<TExpandedProp>`
	${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_LARGE)};
	color: ${colors.LINK_BLUE};
	margin-top: 0.5rem;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}

	${({ $isExpanded }) => $isExpanded && localizeTextExpanded}

	@media (max-width: 768px) {
		${({ $isExpanded }) =>
			getFontDetailsByLabel(
				$isExpanded
					? TYPE_LABELS.UI_LABEL_SMALL
					: TYPE_LABELS.UI_LABEL_REGULAR,
			)}
	}
`;

export const StyledLink = styled.a`
	&.block {
		${getFontDetailsByLabel(TYPE_LABELS.PARAGRAPH_SMALL)}
		color: ${colors.GREY_DS.G3};
		text-decoration: underline;
		padding-top: 0.5rem;
		border-top: 1px solid ${colors.GREY_DS.G6};
		margin-top: 0.5rem;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
`;

export const ReviewHeader = styled.div<
	TExpandedProp & { $hasCountryDetails?: boolean }
>`
	display: grid;
	grid-template-areas: ${({ $isExpanded, $hasCountryDetails }) =>
		!$isExpanded
			? "'image name' 'image country-date' 'rating rating'"
			: $hasCountryDetails
			? "'image name rating' 'image country-date rating'"
			: "'image name rating'"};
	grid-template-columns: min-content 1fr max-content;
	margin: 0.625rem 0 0.75rem 0;
	${({ $isExpanded }) =>
		$isExpanded
			? 'margin-top: 0;'
			: 'grid-row-gap: 0.25rem; grid-column-gap: 0.3125rem;'}
	align-items: center;
	.review-time {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)};
		color: ${colors.GREY_DS.G3};
	}

	.reviewer-name {
		overflow: hidden;
		width: 100%;
		${({ $isExpanded }) =>
			getFontDetailsByLabel(
				$isExpanded
					? TYPE_LABELS.HEADING_XS
					: TYPE_LABELS.HEADING_SMALL,
			)};
		color: ${colors.GREY_DS.G2};
		text-overflow: ellipsis;
		word-wrap: break-word;
		white-space: nowrap;
		grid-area: name;
	}

	@media (max-width: 768px) {
		.reviewer-name {
			margin-bottom: 0.125rem;
		}
	}
`;

export const RatingContainer = styled.div<TExpandedProp>`
	grid-area: rating;
	${({ $isExpanded }) =>
		$isExpanded ? 'justify-self: end;' : 'margin-top: 0.5rem;'}
	${InnerRatingContainer} {
		.star-count {
			color: ${colors.BRAND_COLORS.HEADOUT_CANDY};
			${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR_HEAVY)};
		}
		svg {
			height: 1rem;
			width: 1rem;
		}
	}
`;

export const ReviewerCountryName = styled.div<TExpandedProp>`
	${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)};
	color: ${colors.GREY_DS.G3};
	position: relative;
	${({ $isExpanded }) =>
		$isExpanded
			? css`
					${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_SMALL)};
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
			  `
			: css`
					${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)};
					&::after {
						content: '';
						display: inline-block;
						height: 0.25rem;
						width: 0.25rem;
						border-radius: 0.25rem;
						background: #d9d9d9;
						margin: 0 0.375rem;
						transform: translateY(-50%);
					}
			  `}
`;

export const ReviewerCountryFlag = styled.div<TExpandedProp>`
	display: inline-block;
	border-radius: 50%;
	overflow: hidden;
	position: absolute;
	border: 0.0313rem solid ${colors.GREY_DS.G6};
	right: -0.125rem;
	bottom: -0.0313rem;
	${({ $isExpanded }) =>
		!$isExpanded
			? css`
					height: 1.25rem;
					width: 1.25rem;
			  `
			: css`
					height: 1rem;
					width: 1rem;
			  `}
	img {
		height: 100%;
		transform: scale(1.5) translateY(10%);
	}
`;

export const CountryAndDateContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	grid-area: country-date;
`;
