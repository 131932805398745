import { css } from '@headout/pixie/css';

export const cancellationLayoutStyle = css({
	display: 'flex',
	flexDirection: 'column',
	paddingX: 'space.16',
	paddingY: 'space.20',
	width: '100%',
	maxWidth: '792px',
	height: 'max-content',
	maxHeight: '80svh',
	gap: 'space.12',
	position: 'relative',

	'@media (min-width: 768px)': {
		gap: 'space.8',
		padding: 'space.24',

		'& img': {
			marginBottom: 'space.8',
		},
	},
});

export const boostersContainerStyles = css({
	display: 'flex',
	gap: 'space.12',
	alignItems: 'center',

	'& > span': {
		display: 'flex',
		gap: 'space.2',
		alignItems: 'center',
	},

	'@media (min-width: 768px)': {
		paddingBottom: 'space.20',
		borderBottom: '1px solid #e2e2e2',
	},
});

export const cancellationInfoListStyles = css({
	paddingLeft: '1.25rem',
	paddingRight: '0.5rem',
	paddingBottom: '1rem',
	marginTop: '-space.4',
	marginBottom: '4rem',
	overflowY: 'auto',

	'& li': {
		listStyleType: 'square',
		textStyle: 'Semantics/Para/Regular',

		_marker: {
			fontSize: '0.75em',
		},
	},

	'@media (min-width: 768px)': {
		paddingRight: 'unset',
		paddingBottom: 'unset',
		marginTop: 'space.8',
		marginBottom: 'unset',

		'& li': {
			textStyle: 'Semantics/Para/Medium',
		},
	},
});

export const buttonContainerStyles = css({
	padding: 'space.16',
	boxShadow: 'shadow.top.1',
	backgroundColor: 'semantic.surface.light.white',
	position: 'absolute',
	left: 0,
	right: 0,
	bottom: 0,

	'@media (min-width: 768px)': {
		display: 'none',
	},
});

export const infoPopupStyles = css({
	'& .core-modal-container': {
		background: 'rgba(0, 0, 0, 0.4)',
	},
	'& .core-modal-content': {
		minWidth: 'auto!',
		borderRadius: 'radius.12',
	},
});
