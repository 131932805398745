import React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { NextRouter } from 'next/router';

import type { Itinerary } from 'Containers/common/Itinerary/interface';

import type { SectionNavigatorItem } from 'Components/desktop/sectionNavigator/interface';

import { sendVariableToDataLayer, trackEvent } from 'Utils/analytics';
import { getCategoriesAndSubCategoriesInfo } from 'Utils/collectionsUtils';
import { formatPrice } from 'Utils/gen';
import {
	isHOHOItinerary as checkIfHOHOItinerary,
	isItineraryValid,
} from 'Utils/itineraryUtils';
import { getLocalizationLabels } from 'Utils/localizationUtils';
import { getPrimaryCategoryId, getProductPageTitle } from 'Utils/productUtils';
import { getReviewsFromStore } from 'Utils/reviewUtils';
import {
	getBooking,
	getCalendarInventories,
	getCitiesMap,
	getCollectionCardIdsByCityCode,
	getCurrentLanguageCode,
	getCurrentPage,
	getHost,
	getItineraries,
	getLanguages,
	getLocalizedContentLanguageCode,
	getNearByCityCodes,
	getPoiInfo,
	getProduct,
	getSandboxID,
	getSelectedVariantId,
	getUser,
	isBot as checkIfBot,
} from 'Utils/stateUtils';

import { fetchCalendarInventories } from 'Thunks/calendar';
import { fetchProduct } from 'Thunks/product';
import { fetchReviewsByTgid } from 'Thunks/review';
import { fetchSimilarProducts } from 'Thunks/similarProducts';
import { fetchTravelerMediaByTgid } from 'Thunks/travelerMedia';
import {
	resetBookingStore,
	setBookingStage,
	setBookingVariantId,
} from 'Actions/booking';
import { changeLocalizedContentLanguage } from 'Actions/language';
import { changePage } from 'Actions/page';

import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from 'Constants/analytics';
import { PAGE_TYPE } from 'Constants/constants';
import { strings } from 'Constants/strings';

type TProductPageStoreProps = ConnectedProps<typeof connectProductPage>;

type ProductPageElementsProps = {
	citiesMap?: any;
	currentCity?: any;
	currentCityCode?: string;
	product?: any;
	reviews?: any;
	topReviews?: any;
	id?: string;
	lang?: string;
	langDisplayName?: string;
	params?: any;
	location?: any;
	user?: any;
	query?: any;
	similarProductIds?: any;
	resetBookingStore: (...args: any[]) => any;
	changePage: (...args: any[]) => any;
	fetchProduct: (...args: any[]) => any;
	fetchTravelerMediaByTgid: (...args: any[]) => any;
	fetchCalendarInventories: (...args: any[]) => any;
	fetchSimilarProducts: (...args: any[]) => any;
	setBookingStage: (...args: any[]) => any;
	changeCity: (...args: any[]) => any;
	setBookingVariantId: (...args: any[]) => any;
	changeLocalizedContentLanguage: (payload: { lang: string }) => void;
	fetchReviews: TProductPageStoreProps['fetchReviews'];
	host: string;
	variantId?: number;
	countryCode?: string;
	inventoryData?: any;
	hsid?: string;
	isSeatmap?: boolean;
	isBot?: boolean;
	poiInfo?: any;
	itineraryData?: Itinerary[];
	languageCode: string;
	router: NextRouter;
};

class ProductPageElements extends React.Component<ProductPageElementsProps> {
	onComponentMount = (secondaryCity?: string) => {
		const {
			id,
			lang,
			changePage,
			fetchProduct,
			fetchTravelerMediaByTgid,
			fetchCalendarInventories,
			fetchSimilarProducts,
			resetBookingStore,
		} = this.props;
		changePage(PAGE_TYPE.EXPERIENCE);
		fetchProduct({
			id,
			lang,
			secondaryCity,
			message: `product page - client - ${window?.location?.href}`,
		});
		fetchTravelerMediaByTgid({ tgId: id ?? '' });
		fetchCalendarInventories({ id, shouldFetchLivePrice: true });
		fetchSimilarProducts({ id, lang });
		resetBookingStore({ id });
	};

	componentDidMount() {
		const { id, lang, product, currentCityCode, resetBookingStore } =
			this.props;
		const { currency, listingPrice, city: productCity } = product || {};
		if (listingPrice) {
			const { finalPrice } = listingPrice;
			const experiencePrice = formatPrice(finalPrice, currency);
			sendVariableToDataLayer({
				name: ANALYTICS_PROPERTIES.EXPERIENCE_PRICE,
				value: experiencePrice,
			});
		}

		const productPrimaryCityCode = productCity.code;
		const secondaryCity =
			currentCityCode !== productPrimaryCityCode ? currentCityCode : '';

		resetBookingStore({ id, lang });
		this.onComponentMount(secondaryCity);
		this.updateDocumentTitle();
	}

	componentDidUpdate(prevProps: ProductPageElementsProps) {
		const { id, product, currentCityCode } = this.props;
		if (id !== prevProps.id) {
			const productPrimaryCityCode = product.city.code;
			const secondaryCity =
				currentCityCode !== productPrimaryCityCode
					? currentCityCode
					: '';

			this.onComponentMount(secondaryCity);
		}
		this.updateDocumentTitle();
	}

	shouldDisableTranslate = () => {
		const { language } = this.props.product;
		// @ts-expect-error TS(2532): Object is possibly 'undefined'.
		return language === this.props.lang.toUpperCase();
	};

	updateDocumentTitle = () => {
		const { product } = this.props;
		document.title = getProductPageTitle(product) || document.title;
	};

	setMachineTranslateCalloutBannerVisibility = () => {
		const { product } = this.props;
		this.setState((prevState: any) => ({
			...prevState,
			showMachineTranslatedCalloutBanner:
				product.contentMachineTranslated,
		}));
	};

	handleMachineTranslatedCalloutPrimaryCTAClick = async () => {
		const {
			fetchProduct,
			id,
			lang: selectedLang,
			product,
			currentCityCode,
			changeLocalizedContentLanguage,
		} = this.props;
		const productPrimaryCityCode = product.city.code;

		const secondaryCity =
			currentCityCode !== productPrimaryCityCode ? currentCityCode : '';
		const lang = (this.state as any).isSwitchedToOriginalLang
			? selectedLang!
			: 'en';
		this.setState((prevState: any) => ({
			...prevState,
			isContentTranslating: true,
		}));
		const [localizedStrings] = await Promise.all([
			getLocalizationLabels({ lang }),
			fetchProduct({
				id,
				lang,
				secondaryCity,
				isTranslationRequest: lang === 'en',
				message: `product page - machine translation - client - ${window?.location?.href}`,
			}),
		]);
		if (localizedStrings) {
			strings.setContent({ default: localizedStrings });
			changeLocalizedContentLanguage({ lang });
			this.trackContentTranslationToggle(
				!(this.state as any).isSwitchedToOriginalLang,
			);
			this.setState((prevState: any) => ({
				...prevState,
				isSwitchedToOriginalLang: !prevState.isSwitchedToOriginalLang,
				isContentTranslating: false,
			}));
		}
	};

	getAccordionHeading(element: HTMLDetailsElement): string | undefined {
		const target: HTMLHeadingElement | null =
			element.querySelector('summary h2');

		return target?.innerText;
	}

	generateNavigationSections(): SectionNavigatorItem[] {
		const nodes = document.querySelectorAll('details');
		const headings: string[] = [];
		const itinerarySectionHeading = this.getItinerarySectionHeading();
		const newSections: string[] = [itinerarySectionHeading];
		nodes.forEach(elem => {
			const heading = this.getAccordionHeading(elem);
			if (heading) {
				headings.push(heading.trim());
			}
		});

		nodes.forEach((elem, index) => {
			elem.id = headings[index];
		});

		return Array.from(nodes).map(({ id }) => {
			if (newSections.includes(id)) {
				return {
					sectionId: id,
					label: id,
					isNew: true,
				};
			}
			return {
				sectionId: id,
				label: id,
			};
		});
	}

	getItinerarySectionHeading() {
		const { itineraryData } = this.props;
		const showItinerary = this.shouldShowItinerarySection();
		const isHOHOItinerary = showItinerary
			? checkIfHOHOItinerary(itineraryData?.[0]!)
			: false;

		if (isHOHOItinerary) {
			return strings.HOHO.ROUTES_SCHEDULES;
		} else {
			return strings.ITINERARY.TAB;
		}
	}

	shouldShowItinerarySection() {
		const { itineraryData } = this.props;
		const isPreviewMode = this.isItineraryInPreviewMode();
		return (
			itineraryData?.length &&
			itineraryData.every(itinerary =>
				isItineraryValid(itinerary, isPreviewMode),
			)
		);
	}

	shouldShowSummarySection(summaryData: any) {
		const { itineraryData } = this.props;
		const showItinerary = this.shouldShowItinerarySection();
		const isHOHOItinerary = showItinerary
			? checkIfHOHOItinerary(itineraryData?.[0]!)
			: false;

		if (isHOHOItinerary) {
			return false;
		} else {
			return summaryData;
		}
	}

	isItineraryInPreviewMode() {
		const { query } = this.props.router;
		const { bypassCache, itineraryPreviewId } = query;
		return !!(bypassCache && itineraryPreviewId);
	}

	getItineraryPreviewId() {
		const { query } = this.props.router;
		const { itineraryPreviewId } = query;
		return Number(itineraryPreviewId);
	}

	trackContentTranslationToggle = (isSwitchedToOriginalLang: boolean) => {
		const { product, lang } = this.props;
		trackEvent({
			eventName:
				ANALYTICS_EVENTS.EXPERIENCE_PAGE.TRANSLATE_CONTENT_LINK_CLICKED,
			Action: isSwitchedToOriginalLang
				? 'View Original'
				: 'Translate Content',
			[ANALYTICS_PROPERTIES.EXPERIENCE_ID]: product.id,
			[ANALYTICS_PROPERTIES.LANGUAGE]: lang,
			[ANALYTICS_PROPERTIES.ENTITY_ID]: product.id,
			[ANALYTICS_PROPERTIES.CONTENT_TYPE]: 'Experience',
		});
	};
}

const mapStateToProps = (state: any, ownProps: any) => {
	const { id, lang, query } = ownProps;
	const product = getProduct(state, id);
	const currentCityCode = state?.city?.currentCityCode;
	const similarProductIds =
		state?.similarProductStore?.[String(id)]?.productIdList || [];
	const currentPage = getCurrentPage(state);
	const collectionIds = getCollectionCardIdsByCityCode(
		state,
		currentCityCode,
	);
	const nearByCityCodes = getNearByCityCodes(state);
	const primaryCategory = getPrimaryCategoryId(product);
	const categoriesAndSubCategoriesInfo = getCategoriesAndSubCategoriesInfo(
		state,
		{
			cityCode: currentCityCode,
			isExperiencePage: true,
			lang,
			primaryCategory,
		},
	);
	const languages = getLanguages(state);
	const langDisplayName = languages.find(
		(language: any) => language.code === lang,
	)?.name;
	const isBot = checkIfBot(state);
	const localizedContentLanguageCode = getLocalizedContentLanguageCode(state);
	const productStoreKey =
		lang === localizedContentLanguageCode ? 'byId' : 'byIdInEnglish';

	const poiInfo = getPoiInfo(state, { tgid: id });

	return {
		id,
		query,
		lang,
		langDisplayName,
		currentCity: state?.city?.citiesMap?.[currentCityCode],
		currentCityCode,
		citiesMap: getCitiesMap(state),
		product: state?.productStore?.[productStoreKey]?.[String(id)],
		inventoryData: getCalendarInventories(state, id),
		reviews: getReviewsFromStore(state?.reviewStore, id),
		user: getUser(state),
		host: getHost(state),
		hsid: getSandboxID(state),
		similarProductIds,
		cookies: ownProps.cookies,
		booking: getBooking(state, String(id)),
		variantId: getSelectedVariantId(state, String(id)),
		currentPage,
		collectionIds,
		nearByCityCodes,
		categoriesAndSubCategoriesInfo,
		isBot,
		poiInfo,
		itineraryData: getItineraries(state, id)?.itineraries,
		languageCode: getCurrentLanguageCode(state),
	};
};

const mapDispatchToProps = {
	resetBookingStore,
	changePage: changePage,
	fetchProduct: fetchProduct,
	fetchTravelerMediaByTgid: fetchTravelerMediaByTgid,
	fetchSimilarProducts: fetchSimilarProducts,
	fetchCalendarInventories: fetchCalendarInventories,
	setBookingStage: setBookingStage,
	setBookingVariantId: setBookingVariantId,
	changeLocalizedContentLanguage: changeLocalizedContentLanguage,
	fetchReviews: fetchReviewsByTgid,
};

const connectProductPage = connect(mapStateToProps, mapDispatchToProps);

const serverFunctions = {};

export default ProductPageElements;

export { connectProductPage, serverFunctions };
