export enum CalloutBannerTypes {
	isDummy = 'isDummy',
	isEscortedEntry = 'isEscortedEntry',
	isTicketExchange = 'isTicketExchange',
	isPseudoOpenDated = 'isPseudoOpenDated',
	isMachineTranslatedContent = 'isMachineTranslatedContent',
	isHTMLBasedContent = 'isHTMLBasedContent',
	isRedemptionTypeScan = 'isRedemptionTypeScan',
	isRedemptionTypeShow = 'isRedemptionTypeShow',
	isRedemptionTypeExchange = 'isRedemptionTypeExchange',
	isRedemptionTypeHOHO = 'isRedemptionTypeHOHO',
	isRedemptionTypeHosted = 'isRedemptionTypeHosted',
	isRedemptionTypeLive = 'isRedemptionTypeLive',
	isRedemptionTypeGuidedTour = 'isRedemptionTypeGuidedTour',
}

export type CalloutBannerProps = {
	type: CalloutBannerTypes;
	availableSlotsLink?: string;
	bookingAdvanceHour?: string;
	slotSubmissionLink?: string;
	isSwitchedToOriginalLang?: boolean;
	currentLangDisplayName?: string;
	onPrimaryCtaClick?: () => void;
	htmlContent?: string;
};
