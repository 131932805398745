import { sva } from '@headout/pixie/css';

export const overlayStyles = sva({
	slots: [
		'titleContainer',
		'infoIcon',
		'overlayContainer',
		'overlayHeading',
		'overlayContents',
		'overlayCross',
		'backdrop',
		'portal',
	],
	base: {
		titleContainer: {
			display: 'flex',
			flexDirection: 'row',
			gap: 'space.8',
			pos: 'relative',
			cursor: 'pointer',
			alignItems: 'center',

			'@media (max-width: 768px)': {
				marginBottom: 'space.16',
			},
		},

		infoIcon: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',

			'& svg': {
				height: '[1rem]',
				width: '[1rem]',
			},
		},

		overlayContainer: {
			cursor: 'default',
			pos: 'absolute',
			left: 0,
			top: 'calc(100% + 0.5rem)',
			zIndex: 3,
			display: 'flex',
			flexDir: 'column',
			borderRadius: 'radius.12',
			boxShadow: '0px 1px 6px 1px #1111111a, 0px 1px 4px 0px #1111110d',
			backgroundColor: 'core.primary.white',
			w: '[25rem]',
			overflow: 'hidden',
			opacity: 'opacity.0',
			transition: 'opacity .3s',

			'&[data-trust-overlay-open="true"]': {
				opacity: 'opacity.100',
			},

			'@media (max-width: 768px)': {
				position: 'fixed',
				top: '100%',
				width: '100vw',
				transition: 'transform .3s',
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,

				'&[data-trust-overlay-open="true"]': {
					transform: 'translateY(-100%)',
				},
			},
		},

		overlayHeading: {
			textStyle: 'Semantics/Subheading/Large',
			color: 'semantic.text.grey.1',
			backgroundColor: 'semantic.surface.light.grey.2',
			padding: 'space.12',

			'@media (max-width: 768px)': {
				padding: 'space.16',
				paddingTop: 'space.24',
				display: 'flex',
				justifyContent: 'space-between',
			},
		},

		overlayContents: {
			padding: 'space.12',
			textStyle: 'Semantics/Para/Medium',

			'@media (max-width: 768px)': {
				padding: 'space.16',
				paddingBottom: 'space.32',
				backgroundColor: 'colorPalette.surface.light.white',
			},
		},

		overlayCross: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			background: 'transparent',
			border: 'none',
			outline: 'none',
			padding: 'space.4',

			'& svg': {
				height: '[0.75rem]',
				'& path': {
					stroke: 'core.primary.black',
				},
			},
		},

		backdrop: {
			position: 'fixed',
			content: '',
			background: 'core.primary.black',
			top: '[3.75rem]',
			height: '100vh',
			width: '100vw',
			left: 0,
			zIndex: 1,
			opacity: 'opacity.0',
			transition: 'opacity .3s',
			touchAction: 'none',

			'&[data-trust-overlay-open="true"]': {
				opacity: 'opacity.20',
			},
		},

		portal: {
			zIndex: 10001,
			pos: 'fixed',
		},
	},
});
