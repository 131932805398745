import {
	EItineraryType,
	type Itinerary,
} from 'Containers/common/Itinerary/interface';

export const isItineraryValid = (
	itinerary: Itinerary,
	isPreviewMode: boolean = false,
) => {
	const isActive = isPreviewMode ? true : itinerary?.active;
	const hasSections = itinerary?.sections?.length;

	return isActive && hasSections;
};

export const isHOHOItinerary = (itinerary: Itinerary) => {
	return itinerary?.type === EItineraryType.Hoho;
};

export const isCruisesItinerary = (itinerary: Itinerary) => {
	return itinerary?.type === EItineraryType.Cruise;
};
