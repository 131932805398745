import dayjs from 'dayjs';

import {
	OperatingHoursClosed,
	OperatingHoursOpen,
} from 'Assets/svg/descriptors/operatingHours';

import { getHumanReadableTime } from 'Utils/dateUtils';

import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';

import {
	EProductOperatingScheduleStatus,
	type TLongDescriptorData,
	type TProductOperatingScheduleInfo,
} from './interface';

export const getOperatingHoursDescriptorData = (
	scheduleInfo: TProductOperatingScheduleInfo,
): TLongDescriptorData => {
	const { status, openingTime, closingTime, closestOpenDate } = scheduleInfo;

	switch (status) {
		case EProductOperatingScheduleStatus.OPEN:
			return {
				label: strings.PPD_OPEN_TODAY,
				icon: <OperatingHoursOpen />,
				background: colors.FADED_GREEN,
				labelColor: colors.GREEN_3,
				subtext:
					openingTime && closingTime
						? `${getHumanReadableTime(
								openingTime,
						  )} - ${getHumanReadableTime(closingTime)}`
						: '',
			};
		case EProductOperatingScheduleStatus.OPENS_ON:
			const hoursDifference = dayjs(closestOpenDate).diff(
				dayjs(),
				'hours',
			);

			if (hoursDifference <= 24) {
				return {
					label: strings.PPD_OPENS_TOMORROW,
					icon: <OperatingHoursClosed />,
					background: colors.GREY_DS.G8,
					subtext:
						openingTime && closingTime
							? `${getHumanReadableTime(
									openingTime,
							  )} - ${getHumanReadableTime(closingTime)}`
							: '',
				};
			} else if (hoursDifference <= 7 * 24) {
				return {
					label: strings.formatString(
						strings.PPD_OPENS_ON,
						dayjs(closestOpenDate).format('dddd'),
					),
					icon: <OperatingHoursClosed />,
					background: colors.GREY_DS.G8,
				};
			}

			return {
				label: strings.formatString(
					strings.PPD_OPENS_ON,
					closestOpenDate,
				),
				icon: <OperatingHoursClosed />,
				background: colors.GREY_DS.G8,
			};
		case EProductOperatingScheduleStatus.CLOSED:
		default:
			return {
				label: strings.CLOSED,
				icon: <OperatingHoursClosed />,
				background: colors.GREY_DS.G8,
			};
	}
};
