import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import Conditional from 'Components/common/conditional';
import Image from 'Components/common/image';
import LateralCarousel from 'Components/common/lateralCarousel';
import LP from 'Components/common/localizedTags/localizedParagraph';
import LSpan from 'Components/common/localizedTags/localizedSpan';
import Rating from 'Components/common/rating';
import RenderOneOf from 'Components/common/renderOneOf';
import ReviewStoryWrapper from 'Components/common/reviewCarouselStoryWrapper';
import type { TReviewStoryRef } from 'Components/common/reviewCarouselStoryWrapper/interface';

import { trackEvent } from 'Utils/analytics';
import dayjs from 'Utils/dayjsUtil';
import { onEnterKeyPress } from 'Utils/gen';
import { getCountryFlagUrl } from 'Utils/imageUtils';
import PlatformUtils from 'Utils/platformUtils';
import { getRandomImage } from 'Utils/reviewUtils';
import { getCurrentPage } from 'Utils/stateUtils';
import { capitalizeFirstLetter, getFirstName } from 'Utils/stringUtils';

import type { TReviewMedia } from 'ReduxTypes/review';

import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from 'Constants/analytics';
import {
	DEFAULT_REVIEWER_NAME,
	LANGUAGE_SORT_ORDER,
	PAGE_TYPE,
} from 'Constants/constants';
import { VARIANTS } from 'Constants/experiments';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';

import type { TProps } from './interface';
import {
	CountryAndDateContainer,
	DesktopCarouselContainer,
	LocalizeText,
	MobileCarouselContainer,
	MobileCarouselLastSlide,
	MobileCarouselWrapper,
	RatingContainer,
	ReadMoreBtn,
	ReviewContent,
	ReviewContentWrapper,
	ReviewDateTime,
	ReviewerCountryFlag,
	ReviewerCountryName,
	ReviewerImage,
	ReviewHeader,
	ReviewHeaderCompact,
	ReviewInfo,
	ReviewWrapper,
	StyledLink,
} from './style';

export default function ReviewElement({
	nonCustomerName,
	rating,
	content,
	reviewerImageUrl,
	reviewTime,
	reviewMedias = [],
	experimentVariant,
	nonCustomerCountryCode,
	nonCustomerCountryName,
	translatedContent,
	useTranslatedContent,
	sourceLanguage,
	currentLanguage = 'en',
	isExpanded = false,
	tourGroup,
	compactHeader,
	onImageClick,
}: TProps) {
	const [showMoreBtn, setShowMoreBtn] = useState(false);
	const [readMore, setReadMore] = useState(true);
	const [reviewContent, setReviewContent] = useState(
		useTranslatedContent ? translatedContent : content,
	);

	const reviewContentRef = useRef<HTMLDivElement>(null);
	const reviewStoryRef = useRef<TReviewStoryRef>(null);

	const getReviewTime = dayjs(reviewTime).format('MMM, YYYY');
	const isDesktop = PlatformUtils.isDesktop();

	const isSupportedLanguage =
		sourceLanguage &&
		LANGUAGE_SORT_ORDER.includes(sourceLanguage.toLowerCase());

	const getCapitalizedFirstName = () => {
		return capitalizeFirstLetter(
			(nonCustomerName ?? '').toLowerCase().trim() ===
				DEFAULT_REVIEWER_NAME
				? nonCustomerName ?? ''
				: getFirstName(nonCustomerName),
		);
	};

	const toggleReviewContent = () => {
		const shouldTranslate = reviewContent === content;
		const action = shouldTranslate ? 'Translate Review' : 'View Original';
		const finalContent = shouldTranslate ? translatedContent : content;
		setReviewContent(finalContent);
		trackEvent({ eventName: ANALYTICS_EVENTS.REVIEW_LOC, Action: action });
	};

	const toggleContent = () => {
		setReadMore(!readMore);

		trackEvent({
			eventName: 'Read More Clicked',
			[ANALYTICS_PROPERTIES.RATING]: rating,
			[ANALYTICS_PROPERTIES.IS_MEDIA_PRESENT]: !!reviewMedias?.length,
		});
	};

	const onMediaClick = (index: number) => {
		if (!onImageClick || onImageClick(index)) {
			reviewStoryRef.current?.showStory(index, rating);
		}
	};

	useEffect(() => {
		if (!reviewContentRef.current || !readMore) return;
		const { scrollHeight, offsetHeight } = reviewContentRef.current;
		const shouldShowButton = scrollHeight > offsetHeight;
		setShowMoreBtn(shouldShowButton);
	}, [reviewContent]);

	const LateralCarouselSetting = {
		className: 'review-media-carousel',
		elementsToShow: 4,
		elementsToSlide: 1,
		isCardsArrowsRequired: true,
		isCollectionCarousel: true,
	};

	const DesktopCarousel = reviewMedias?.map(({ url }, index) => (
		<div className='review-image-container' key={index}>
			<Image
				width={180}
				height={240}
				layout={'fill'}
				src={url}
				alt='review-image'
				onClick={() => onMediaClick(index)}
				enhance
			/>
		</div>
	));

	let mobileMedia: TReviewMedia[] = reviewMedias;
	const maxImagesToBeDisplayed = 3;
	const balanceImages = mobileMedia.length - maxImagesToBeDisplayed;

	if (reviewMedias.length > maxImagesToBeDisplayed) {
		mobileMedia = reviewMedias.slice(0, maxImagesToBeDisplayed);
	}

	const mobileCarousel = mobileMedia?.map(({ url }, index) => (
		<MobileCarouselWrapper
			key={index}
			$isExpanded={isExpanded}
			onClick={() => onMediaClick(index)}
			onKeyDown={e => onEnterKeyPress(e, onMediaClick, index)}
			role='button'
			tabIndex={0}
		>
			<Image
				width={isExpanded ? 80 : 98}
				height={isExpanded ? 106 : 132}
				src={url}
				alt='review-image'
				enhance
			/>

			<Conditional if={index === 2 && balanceImages}>
				<MobileCarouselLastSlide $isExpanded={isExpanded}>
					<div className='flexContainer'>
						<p className='balanceImages'>{strings.SEE_MORE}</p>
					</div>
				</MobileCarouselLastSlide>
			</Conditional>
		</MobileCarouselWrapper>
	));

	const currentPage = useSelector(state => getCurrentPage(state));
	const hideCountryFlag =
		currentPage === PAGE_TYPE.EXPERIENCE ||
		currentPage === PAGE_TYPE.REVIEW;

	return (
		<>
			<ReviewStoryWrapper
				nonCustomerName={nonCustomerName ?? ''}
				rating={rating}
				reviewMedias={reviewMedias}
				content={reviewContent}
				reviewerImageUrl={
					reviewerImageUrl ?? getRandomImage(nonCustomerName || '')
				}
				reviewTime={reviewTime}
				reviewStoryRef={reviewStoryRef}
				nonCustomerCountryCode={nonCustomerCountryCode}
				nonCustomerCountryName={nonCustomerCountryName}
			/>

			<ReviewWrapper $isExpanded={isExpanded}>
				<RenderOneOf
					positionalConditions={[
						Boolean(compactHeader),
						!compactHeader,
					]}
				>
					<ReviewHeaderCompact>
						<ReviewerImage>
							<Image
								className='reviewer-image'
								alt={nonCustomerName}
								src={
									reviewerImageUrl ??
									getRandomImage(nonCustomerName || '')
								}
								width={isExpanded ? 36 : 48}
								height={isExpanded ? 36 : 48}
							/>
						</ReviewerImage>

						<ReviewInfo>
							<LP className='reviewer-name block'>
								{getCapitalizedFirstName()}
							</LP>

							<Rating
								value={rating}
								fillColor={colors.BRAND_COLORS.HEADOUT_CANDY}
							/>
						</ReviewInfo>

						<ReviewDateTime>{getReviewTime}</ReviewDateTime>
					</ReviewHeaderCompact>

					<ReviewHeader
						$isExpanded={isExpanded}
						$hasCountryDetails={
							!!nonCustomerCountryName && !hideCountryFlag
						}
					>
						<ReviewerImage $isExpanded={isExpanded}>
							<Image
								className='reviewer-image'
								alt={nonCustomerName}
								src={
									reviewerImageUrl ??
									getRandomImage(nonCustomerName || '')
								}
								width={isExpanded ? 36 : 48}
								height={isExpanded ? 36 : 48}
							/>
							<Conditional
								if={nonCustomerCountryCode && !hideCountryFlag}
							>
								<ReviewerCountryFlag $isExpanded={isExpanded}>
									<Image
										src={getCountryFlagUrl(
											nonCustomerCountryCode!,
										)}
										alt={
											nonCustomerCountryCode ??
											'country flag'
										}
										height={14}
										width={14}
										className='country-flag'
									/>
								</ReviewerCountryFlag>
							</Conditional>
						</ReviewerImage>

						<LP className='reviewer-name block'>
							{getCapitalizedFirstName()}
						</LP>

						<RatingContainer $isExpanded={isExpanded}>
							<Rating
								value={rating}
								fillColor={colors.BRAND_COLORS.HEADOUT_CANDY}
							/>
						</RatingContainer>

						<CountryAndDateContainer>
							<Conditional
								if={nonCustomerCountryName && !hideCountryFlag}
							>
								<ReviewerCountryName $isExpanded={isExpanded}>
									{nonCustomerCountryName}
								</ReviewerCountryName>
							</Conditional>
							<Conditional if={!isExpanded}>
								<LSpan className='review-time'>
									{getReviewTime}
								</LSpan>
							</Conditional>
						</CountryAndDateContainer>
					</ReviewHeader>
				</RenderOneOf>

				<Conditional if={reviewMedias?.length && isExpanded}>
					<MobileCarouselContainer $isExpanded={isExpanded}>
						{mobileCarousel}
					</MobileCarouselContainer>
				</Conditional>

				<Conditional if={reviewContent}>
					<ReviewContentWrapper>
						<ReviewContent
							ref={reviewContentRef}
							readMore={readMore}
							className='translate'
							$isExpanded={isExpanded}
							$shouldLimitLines={
								reviewMedias.length > 0 && useTranslatedContent
							}
						>
							{reviewContent}
						</ReviewContent>

						<Conditional if={showMoreBtn && !isExpanded}>
							<ReadMoreBtn
								className='read-more'
								onClick={toggleContent}
							>
								{readMore
									? `${strings.READ_MORE} +`
									: `${strings.SEE_LESS} -`}
							</ReadMoreBtn>
						</Conditional>
						<Conditional if={useTranslatedContent}>
							<LocalizeText
								onClick={toggleReviewContent}
								$isExpanded={isExpanded}
							>
								{reviewContent === content
									? strings.formatString(
											strings.REVIEW_LOC.TRANSLATE,
											strings.REVIEW_LOC.LANGUAGES[
												currentLanguage.toUpperCase()
											] ??
												strings.REVIEW_LOC.LANGUAGES.EN,
									  )
									: isSupportedLanguage
									? strings.formatString(
											strings.REVIEW_LOC.VIEW_ORIGINAL,
											strings.REVIEW_LOC.LANGUAGES[
												sourceLanguage!.toUpperCase()
											] ??
												strings.REVIEW_LOC.LANGUAGES.EN,
									  )
									: strings.REVIEW_LOC.VIEW_ORIGINAL_NO_LANG}
							</LocalizeText>
						</Conditional>
					</ReviewContentWrapper>
				</Conditional>

				<Conditional
					if={
						reviewMedias.length &&
						experimentVariant === VARIANTS.SHOW_REVIEW_MEDIA
					}
				>
					<RenderOneOf positionalConditions={[isDesktop, !isDesktop]}>
						<DesktopCarouselContainer>
							<LateralCarousel {...LateralCarouselSetting}>
								{DesktopCarousel}
							</LateralCarousel>
						</DesktopCarouselContainer>
						<MobileCarouselContainer>
							{mobileCarousel}
						</MobileCarouselContainer>
					</RenderOneOf>
				</Conditional>
				<Conditional if={isExpanded && tourGroup}>
					<StyledLink
						href={tourGroup?.url}
						className='block'
						onClick={() => {
							trackEvent({
								eventName: 'Collection Page CTA Clicked',
								[ANALYTICS_PROPERTIES.CTA_TYPE]:
									'View Experience',
							});
						}}
					>
						{tourGroup?.urlText}
					</StyledLink>
				</Conditional>
			</ReviewWrapper>
		</>
	);
}
