import { strings } from '@headout/aqua';
import { Button, Text } from '@headout/eevee';

import Image from 'Components/common/image';

import { FLEXI_CANCELLATION_SHIELD_SVG_URL } from '../constants';

import {
	boostersContainerStyles,
	buttonContainerStyles,
	cancellationInfoListStyles,
	cancellationLayoutStyle,
} from './style';
import { SparkGreenSVG, SupplySVG, ZeroQuestionsSVG } from './svgs';

type TFlexibleCancellationInfoProps = {
	isMobile: boolean;
	onRequestClose?: () => void;
};

export const FlexibleCancellationInfo = ({
	isMobile,
	onRequestClose,
}: TFlexibleCancellationInfoProps) => {
	return (
		<div className={cancellationLayoutStyle}>
			<Image
				src={FLEXI_CANCELLATION_SHIELD_SVG_URL}
				alt='flexi-cancellation-shield'
				width={70}
				height={65.55}
			/>

			<Text textStyle={'Semantics/Heading/Medium'}>
				{strings.FLEXIBLE_CANCELLATION.TITLE}
			</Text>

			<div className={boostersContainerStyles}>
				<span>
					<SparkGreenSVG />
					<Text
						textStyle={'Semantics/Subheading/Small'}
						color={'semantic.surface.dark.success.2'}
					>
						{strings.FLEXIBLE_CANCELLATION.FULL_REFUND}
					</Text>
				</span>

				<span>
					<SupplySVG />
					<Text
						textStyle={'Semantics/Subheading/Small'}
						color={'semantic.surface.dark.success.2'}
					>
						{strings.FLEXIBLE_CANCELLATION.INSTANT}
					</Text>
				</span>

				<span>
					<ZeroQuestionsSVG />
					<Text
						textStyle={'Semantics/Subheading/Small'}
						color={'semantic.surface.dark.success.2'}
					>
						{strings.FLEXIBLE_CANCELLATION.NO_QUESTIONS}
					</Text>
				</span>
			</div>

			<Text
				textStyle={
					isMobile
						? 'Semantics/Heading/Small'
						: 'Semantics/UI Label/Large (Heavy)'
				}
				color={
					isMobile ? 'semantic.text.grey.1' : 'semantic.text.grey.2'
				}
				marginTop={'space.16'}
			>
				{strings.FLEXIBLE_CANCELLATION.TNC}
			</Text>

			<ul className={cancellationInfoListStyles}>
				<li>{strings.FLEXIBLE_CANCELLATION.ELIGIBILITY}</li>
				<li>{strings.FLEXIBLE_CANCELLATION.CANCELLATION_DEADLINE}</li>
				<li>{strings.FLEXIBLE_CANCELLATION.REFUND_AMOUNT}</li>
				<li>{strings.FLEXIBLE_CANCELLATION.NON_TRANSFERABLE}</li>
				<li>{strings.FLEXIBLE_CANCELLATION.GUIDELINES}</li>
			</ul>

			<div className={buttonContainerStyles}>
				<Button
					variant='primary'
					size='medium'
					as='button'
					primaryText={strings.OKAY}
					btnType='black'
					onClick={onRequestClose}
				/>
			</div>
		</div>
	);
};
