import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { Text } from '@headout/eevee';

import Conditional from 'Components/common/conditional';

import InfoIconDefault from 'Assets/svg/InfoIconDefault';
import { CrossIconSvg } from 'Assets/svg/mobile/bookingPage';

import useCaptureClickOutside from 'Hooks/useCaptureClickOutside';

import { strings } from 'Constants/strings';

import { PORTAL_ID } from './constants';
import { overlayStyles } from './styles';
import type { TTrustOverlayProps } from './types';

const OverlayContents = ({
	isDesktop = false,
	changeRenderOverlayState,
}: {
	isDesktop?: boolean;
	changeRenderOverlayState: (p: boolean) => void;
}) => {
	const [showOverlay, changeOverlayState] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			changeOverlayState(true);
		}, 10);
	}, []);

	const ref = useRef<HTMLDivElement>(null);

	const close = () => {
		if (!showOverlay) return;
		changeOverlayState(false);
		setTimeout(() => {
			changeRenderOverlayState(false);
		}, 350);
	};

	useCaptureClickOutside(ref, close);

	const styles = overlayStyles();

	return (
		<>
			<Conditional if={!isDesktop}>
				<div
					data-trust-overlay-open={showOverlay}
					onClick={close}
					className={styles.backdrop}
				/>
			</Conditional>
			<div
				data-trust-overlay-open={showOverlay}
				ref={ref}
				className={styles.overlayContainer}
			>
				<div className={styles.overlayHeading}>
					{strings.TRUST_TOOLTIP_HEADER}
					<Conditional if={!isDesktop}>
						<button className={styles.overlayCross} onClick={close}>
							<CrossIconSvg />
						</button>
					</Conditional>
				</div>

				<Text className={styles.overlayContents}>
					{strings.TRUST_TOOLTIP_CONTENT}
				</Text>
			</div>
		</>
	);
};

const Overlay = ({ isDesktop = false }: TTrustOverlayProps) => {
	const [renderOverlay, changeRenderOverlayState] = useState(false);
	const overlayPortal = useRef<Element | null>(null);

	const styles = overlayStyles();

	useEffect(() => {
		if (isDesktop || overlayPortal.current) return;
		const hasPortal = !!document.body.querySelector(`#${PORTAL_ID}`);
		if (!hasPortal) {
			const portalDiv = document.createElement('div');
			portalDiv.id = PORTAL_ID;
			if (styles.portal) portalDiv.className = styles.portal;
			document.body.appendChild(portalDiv);
		}
		overlayPortal.current = document.body.querySelector(`#${PORTAL_ID}`);
	}, []);

	const openOverlay = () => {
		changeRenderOverlayState(true);
	};

	const closeOverlay = () => {
		changeRenderOverlayState(false);
	};

	return (
		<div
			onMouseEnter={openOverlay}
			onMouseLeave={closeOverlay}
			onClick={openOverlay}
			className={styles.titleContainer}
		>
			<Text
				variant='normal'
				textStyle={'Semantics/UI Label/Regular'}
				color={'semantic.text.grey.3'}
			>
				{strings.TRUST_TOOLTIP_HEADER}
			</Text>
			<span className={styles.infoIcon}>
				<InfoIconDefault />
			</span>
			<Conditional if={renderOverlay}>
				{isDesktop ? (
					<OverlayContents
						isDesktop
						changeRenderOverlayState={changeRenderOverlayState}
					/>
				) : (
					overlayPortal.current &&
					createPortal(
						<OverlayContents
							isDesktop={false}
							changeRenderOverlayState={changeRenderOverlayState}
						/>,
						overlayPortal.current,
					)
				)}
			</Conditional>
		</div>
	);
};

export default Overlay;
